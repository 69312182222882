import React, { useEffect, useState } from 'react'
import { Sidebar } from '../../components/navigation/sidebar'
import ConfirmationDialog from '../../components/dialog/confirmation_dialog'
import app_api from '../../config/api'
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import Button from '../../components/button'
import AddTermModal from './AddTermModal'
import { termMaster_columns } from '../../components/tables/tableheader'
import Table from '../../components/tables/table'
import TermMasterHeader from './TermMasterHeader'
import TermTablePagination from './TermTablePagination'
import TermFilterSidebar from './TermFilterSidebar'

export default function TermMaster() {
  const initialModalState = {
    type: '',
    state: false,
    index: null,
    edit_id: '',
    data: {
      name: '',
      startDate: new Date(),
      endDate: new Date(),
      state: null,
    },
  }

  const termList = [
    { id: 'Term I', name: 'Term I' },
    { id: 'Term II', name: 'Term II' },
    { id: 'Term III', name: 'Term III' },
    { id: 'Term IV', name: 'Term IV' },
    { id: 'Holiday', name: 'Holiday' },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('moldKey'))
  )

  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)

  const [termData, setTermData] = useState([])
  const [defaultState, setDefaultState] = useState([])
  const [enabledSub, setEnabledSub] = useState(false)

  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [tabData, setTabData] = useState([])
  const [count, setCount] = useState(0)

  const [stateData, setStateData] = useState([])
  const [selectedState, setSelectedState] = useState('')
  const [managerLocation, setManagerLocation] = useState([])

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [stateFilter, setStateFilter] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [filterData, setFilterData] = useState({
    state: [],
    loading: false,
  })

  const getStateDataByBackend = () => {
    app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        setStateData(
          data.map((item, index) => {
            return { label: item.name, value: item.name }
          })
        )
        setError(null)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getStateByManager = () => {
    const userData = JSON.parse(localStorage.getItem('moldKey'))
    let url = `/location/getStateByLocation`
    let payload = {
        location_id: managerLocation,
        manager_id: userData.id,
      }

    app_api
      .post(url, payload)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false)
        setError(null)
        setCount(res.count)
        setSelectedState([{ value: res[0].state, label: res[0].state }])
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getBatchData = () => {
    let url = `/terms-master/getAll`
    let payload = {
      state: selectedState,
      role: userData.role,
      user_id: userData.id,
    }
    app_api
      .post(url, payload)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false)
        setError(null)
        setFilterData({
          ...filterData,
          loading: false,
        })
        if (res.count <= limit) setPage(0)
        if (res.count < 15) {
          setLimit(res.count)
        } else {
          setLimit(15)
        }
        setTermData(res.data)
        setTabData(res.data)
        setTotalPages(res.count / limit)
        setCount(res.count)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onDeleteBatch = () => {
    const { id, index } = modal
    app_api
      .patch(`/terms-master/${id}`, { archive: false })
      .then((res) => {
        getBatchData()
        toast.success('Successfully deleted')
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const onActiveCategory = (id) => {
    app_api
      .patch(`/terms-master/${id}`, { archive: true })
      .then((res) => {
        getBatchData()
        toast.success('Successfully deleted')
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const cleanModalData = () => {
    setModal(initialModalState)
    if (userData.role == 'admin') {
      setSelectedState(null)
    }
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: tabData[index],
    }))
    setSelectedState([
      { label: tabData[index].state, value: tabData[index].state },
    ])
    setEnabledSub(tabData[index].archive)
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const handleClearFilters = () => {
    setSelectedState()
    setIsFilterSidebarOpen(false)
  }

  const handleFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })
    getBatchData()
    setIsFilterSidebarOpen(false)
  }

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('moldKey')))
    getStateDataByBackend()
    getBatchData()
  }, [])

  useEffect(() => {
    if (userData && userData.role && userData.role == 'manager') {
      setManagerLocation(userData.locationId)
    }
  }, [userData])

  useEffect(() => {
    if (managerLocation && managerLocation.length > 0) {
      getStateByManager()
    }
  }, [managerLocation])

  useEffect(() => {
    if (selectedState && userData.role == "manager") {
      getBatchData()
    }
  }, [selectedState])

  

  return (
    <Sidebar>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteBatch}
        confirmationButtonText="Inactive"
        description="Do you really want to inactivate this data?"
      />

      <AddTermModal
        type={modal.type}
        state={modal.state}
        edit_id={modal.edit_id}
        data={modal.data}
        getBatchData={getBatchData}
        cleanModalData={cleanModalData}
        setModal={setModal}
        termList={termList}
        enabledSub={enabledSub}
        setEnabledSub={setEnabledSub}
        classNames={classNames}
        stateData={stateData}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        userData={userData}
      />

      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <TermMasterHeader cleanModalData={cleanModalData} setModal={setModal} />
        {userData.role == 'admin' && (
          <TermFilterSidebar
            filterData={filterData}
            stateData={stateData}
            selectedState={selectedState}
            isFilterSidebarOpen={isFilterSidebarOpen}
            setSelectedState={setSelectedState}
            setFilterData={setFilterData}
            setIsFilterSidebarOpen={setIsFilterSidebarOpen}
            handleClearFilters={handleClearFilters}
            handleFilters={handleFilters}
          />
        )}
        <Table
          progPage={page}
          totalCount={count}
          columns={termMaster_columns({
            onEditOpen,
            onDeleteOpen,
            onActiveCategory,
          })}
          data={termData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
        <TermTablePagination
          count={count}
          page={page}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          goPrev={goPrev}
          goNext={goNext}
        />
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </Sidebar>
  )
}
