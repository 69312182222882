import { useState, useEffect, useMemo, useCallback } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import Select from 'react-select'
import { batch_columns } from '../components/tables/tableheader'
import Label from '../components/label'
import { Sidebar } from '../components/navigation/sidebar'
import moment from 'moment'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { batchSchema } from '../schema'
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    start_timestamp: '',
    end_timestamp: '',
    is_active: true,
    location_id: '',
  },
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Batch = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [batches, setBatches] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [location, setLocation] = useState([])
  const [locationFilter, setLocationFilter] = useState('')
  const [startDateFilter, setStartDateFilter] = useState(null)
  const [endDateFilter, setEndDateFilter] = useState()
  const [TabData, setTabData] = useState([])
  const [pagingData, setPagingData]= useState({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
      order: "",
      key: "",
    },
  })

  useEffect(() => {
    setPagingData({
      ...pagingData,
      total: TabData.length
    })
  },[TabData])

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          onPaginationChange={onPaginationChange}
          pagingData={tableData}
          columns={batch_columns({ onEditOpen, onDeleteOpen })}
          data={
            TabData
          }
          onEditClose={cleanModalData}
        />
      </>
    )
  }, [TabData])

  const { pageIndex, pageSize, sort, query, total } = pagingData;

  const tableData = useMemo(
    () => ({ pageIndex, pageSize, sort, query, total }),
    [pageIndex, pageSize, sort, query, total]
  );

  const onPaginationChange = (page) => {
    let newService = [...batches]
    setTabData(newService.slice((page-1) * pageSize))
    const newTableData = {...tableData};
    newTableData.pageIndex = page;
    setPagingData(newTableData)
  };

  useEffect(() => {
    if(pageIndex != 1){
      const newService = [...batches]
      setTabData(newService.slice((pageIndex - 1) * pageSize))
    }
  }, [batches])


  const navigation = useNavigate()

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: batches[index],
    }))
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const onNavigation = (url) => {
    navigation(url)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const createBatch = () => {
    app_api
      .post('/batch', modal.data)
      .then((res) => {
        cleanModalData()
        getBatchData()
      })
      .catch((err) => {
        setError(err.toString)
      })
  }

  const updateBatch = (id) => {
    app_api
      .patch(`/batch/${id}`, modal.data)
      .then((res) => {
        getBatchData()
        cleanModalData()
      })
      .catch((err) => {})
  }

  const onDeleteBatch = () => {
    const { id, index } = modal
    app_api
      .patch(`/batch/${id}`,{archive: 1})
      .then((res) => {
        getBatchData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const onModalHandleChange = (name, value) => {
    setModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }))
  }

  const getBatchData = () => {
    app_api
      .get('/batch')
      .then((res) => {
        let data = res.data.filter(l => l.archive == 0)
        setLoading(false)
        setError(null)
        setBatches(data)
        setTabData(data)
        setPagingData({
          ...pagingData,
          total: data.length
        })
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getLocation = () => {
    app_api
      .get('/location')
      .then((res) => {
        let data = res.data.filter(l => l.archive == 0)
        setLoading(false)
        setError(null)
        setLocation(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getBatchData()
    getLocation()
  }, [])

  const renderModal = () => {
    const { type, state, data, edit_id } = modal
    const { name, start_timestamp, end_timestamp, is_active, location_id } =
      data
    return (
      <Formik
        initialValues={data}
        validationSchema={batchSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          if (type === 'add') {
            app_api
              .post('/batch', {
                ...values,
                location_id: String(values.location_id),
              })
              .then((res) => {
                getBatchData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                setError(err.toString)
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/batch/${edit_id}`, {
                ...values,
                location_id: String(values.location_id),
              })
              .then((res) => {
                getBatchData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                setError(err.toString)
                setSubmitting(false)
              })
          }
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
          resetForm
        }) => (
          <Modal
            title={type === 'add' ? 'Add Batch' : 'Edit Batch'}
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-4">
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="name"
                  label="Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Enter Name...'
                  onFocus={()=>setFieldTouched('name',false)}
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
                <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Start Timestamp</label>
                  <span className='text-red-700 ml-1'>*</span></div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="start_timestamp"
                  label="Start Timestamp"
                  type="datetime-local"

                  value={
                    type === 'add'
                      ? values.start_timestamp
                      : moment(values.start_timestamp)
                          .utc()
                          .format('YYYY-MM-DDTHH:MM')
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('start_timestamp',false)}
                />
                {touched.start_timestamp && (
                  <p className="text-red-700 error_msg">
                    {errors.start_timestamp}
                  </p>
                )}
                <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >End Timestamp</label>
                  <span className='text-red-700 ml-1'>*</span></div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="end_timestamp"
                  label="End Timestamp"
                  type="datetime-local"
                  value={
                    type === 'add'
                      ? values.end_timestamp
                      : moment(values.end_timestamp)
                          .utc()
                          .format('YYYY-MM-DDTHH:MM')
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('end_timestamp',false)}
                />
                {touched.end_timestamp && (
                  <p className="text-red-700 error_msg">
                    {errors.end_timestamp}
                  </p>
                )}
                <div className="mt-4"></div>
                <> <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Location</label>
                  <span className='text-red-700 ml-1'>*</span></div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="location_id"
                    id="location_id"
                    isSearchable
                    loadingMessage="Getting location..."
                    placeholder="Select a location"
                    value={
                      values.location_id ? 
                      location?.map(l => ({
                        value: location.find(e => e.id == values.location_id)?.id,
                        label: location.find((e) => e.id == values.location_id)?.name
                      })) : 
                      null
                    }
                    options={location?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        location_id: option.id,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={()=>setFieldTouched('location_id',false)}
                  />
                  {touched.location_id && (
                    <p className="text-red-700 error_msg">
                      {errors.location_id}
                    </p>
                  )}
                </>
                <div className="mt-5 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    {type === 'add' ? 'Add Batch' : 'Update Batch'}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteBatch}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Batch</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the batches.
            </p>
          </div>
          <div className="flex justify-between items-center sm:mt-0 sm:ml-16 sm:flex-none gap-4">
            <Button
              onClick={() =>
                setModal((prev) => ({ ...prev, type: 'add', state: true }))
              }
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {/* <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2">
          <div className="w-1/4 ml-1">
            <Select
              className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              name="location"
              id="location"
              isSearchable
              loadingMessage="Getting Location..."
              placeholder="Location"
              isClearable={true}
              options={location?.map((l) => ({
                ...l,
                label: l.name,
                value: l.id,
              }))}
              onChange={(option) =>
              {
                setLocationFilter(option)
              }}
            />
          </div>
          <div className="w-1/4 ml-1">
            <DatePicker
              className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
              name="start_timestamp"
              id='start_timestamp'
              value={startDateFilter}
              selected={startDateFilter}
              onChange={(date) => setStartDateFilter(date)}
              isClearable
              placeholderText="Start Date"
            />
          </div>
          <div className="w-1/4 ml-1">
            <DatePicker
              className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
              name="end_timestamp"
              id='end_timestamp'
              value={endDateFilter}
              selected={endDateFilter}
              onChange={(date) => setEndDateFilter(date)}
              isClearable
              placeholderText="End Date"
            />
          </div> 
        </div> */}
          {/* <Table
          columns={batch_columns({ onEditOpen, onDeleteOpen, onNavigation })}
          data={batches ? batches.filter((l) =>
          {
            if (locationFilter)
            {
              return l?.location.name == locationFilter.name
            } else return true
          })
            .filter((l) =>
            {
              if (startDateFilter)
              {
                const start = l?.start_timestamp
                return moment(start).format('DD MM YYYY') == moment(startDateFilter).format('DD MM YYYY')
              } else return true
            })
            .filter((l) =>
            {
              if (endDateFilter)
              {
                const end = l?.end_timestamp
                return moment(end).format('DD MM YYYY') == moment(endDateFilter).format('DD MM YYYY')
              } else return true
            })
            : null}
            onEditClose={cleanModalData}
        /> */}
        {renderTable()}
      </div>
    </Sidebar>
  )
}

export default Batch
