import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { naplan_columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import { ArrowUpCircleIcon, Cog8ToothIcon, PhotoIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import classNames from 'classnames'
import { Switch } from '@headlessui/react'
import CurrencyInput from 'react-currency-input-field'
import BigSlideover from '../components/big-slideover'
import { servicesTypeClassesSchema } from '../schema'

const initialModalState = {
  type: 'add',
  state: false,
  index: null,
  id: '',
  data: {
    files: '',
    serviceId:'',
  }
}

const initialCRMModalState = {
  type: 'add',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    cost: '',
    description: '',
    grade: '',
    sessionType: '',
    typeOf: '',
    files: '',
  },
}

const groupSizeOptions = [
  {
    id: 'individual',
    name: 'Individual',
  },
  {
    id: 'group',
    name: 'Group',
  },
]

const typeOfServiceOptions = [
  {
    id: 'Regular',
    name: 'Regular',
  },
  {
    id: 'Workshop',
    name: 'Workshop',
  },
]

const UploadedFiles = () => {
  const [modal, setModal] = useState(initialModalState)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [files, setFiles] = useState([])
  const [tabData, setTabData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [service, setService] = useState([])

  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  const [crmModal, setCrmModal] = useState(initialCRMModalState)
  const [grade, setGrade] = useState([])
  const [exams, setExams] = useState([])
  const [enabledSub, setEnabledSub] = useState(true)

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }
  // Pagination End

  useEffect(() => {
    getUpdateData()
  }, [page, limit, searchFilter])

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  useEffect(() => {
    getServiceData()
    getGradeData()
  }, [])

  const getServiceData = () => {
    let url = `/crm-service/get/workshop`
    app_api
      .get(url)
      .then((res) => {
        let data = res.data.filter((l) => l.id != 45)
        setService(data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getUpdateData = () => {
    let url = `pdf-uploads?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        let data = res.data
        setTabData(data)
        if (res.count <= limit) setPage(0)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
        if (isNaN(res.count / limit)) { setTotalPages(1) } else { setTotalPages(res.count / limit) }
        setCount(res.count)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getGradeData = () => {
    app_api
      .get('/grade-master')
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setGrade(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const serviceOpen = (id) => {
    app_api.get(`/crm-service/${id}`)
    .then((res) => {
      const data = res.data
      console.log(data);
      setEnabledSub(data.archive)
    setExams(data.exam)
      setCrmModal((prev) => ({ ...prev, type:'edit',state: true,data:data,edit_id:id }))
    })
  }

  const uploadedFiles = (id, files) => {
    let fd = new FormData()
    fd.append('id', id)
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        fd.append('file', files[i])
      }
    }
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    app_api
      .post(`/pdf-uploads`, fd, { headers })
      .then((res) => {
        getServiceData()
        setExams(res.data.exam)
        toast.success('Successfully added file')
      }).catch((err) => {
        toast.error('Failed to upload file')
      })
  }

  const onDeleteOpen = (id, index) => {
    console.log(`onDeleteOpen`, id)
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const onDeleteService = () => {
    const { id } = modal
    app_api
      .delete(`pdf-uploads/${id}`)
      .then((res) => {
        getUpdateData()
        toast.success("Successfully deleted")
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error('Please remove from the service')
      })
  }


  const renderModal = () => {
    const { state, data } = modal
    return (

      <Formik
        initialValues={data}
        validationSchema={null}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let fd = new FormData()
          if (values.files && values.files.length > 0) {
            for (let i = 0; i < values.files.length; i++) {
              fd.append('file', values.files[i])
            }
          }
          const headers = {
            'Content-Type': 'multipart/form-data',
          }
          fd.append('id',values.serviceId)
          app_api
            .post('pdf-uploads', fd, { headers })
            .then((res) => {
              if (res) {
                cleanModalData()
                getUpdateData()
                toast.success("Successfully added")
              }
            })
            .catch((err) => {
              toast.error("Failed to upload document")
            })
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
          resetForm,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title="Add PDF"
            open={state}
            setOpen={() => {
              resetForm(true)
              setModal((prev) => ({ ...prev, state: false }))
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-2">
                <div className="mt-4">
                <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Select Service
                        </label>
                        <span className="text-red-500 mx-1">*</span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="serviceId"
                        id="serviceId"
                        isSearchable
                        placeholder="Select service"
                        value={
                          values.serviceId
                            ? service?.map((l) => ({
                              value: service?.find(
                                (e) => e.id == values.serviceId
                              )?.id,
                              label: service?.find(
                                (e) => e.id == values.serviceId
                              )?.name,
                            }))
                            : null
                        }
                        options={service?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          console.log(option)
                          setValues({
                            ...values,
                            serviceId: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                      />
                </div>
                <div className="flex justify-center mb-2 mt-4">
                  <div className="w-full bg-gray-50 p-6 rounded-md shadow-md">
                    <h2 className="text-2xl font-bold text-gray-900 flex justify-center mt-2">Uploads</h2>
                    <div
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        e.preventDefault()
                        const data = e.target.files
                        const newData = Array.from(data)
                        const updatedFiles = [...files, ...newData]
                        setValues({
                          ...values,
                          files: updatedFiles,
                        })
                      }}
                      className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                    >
                      <div className="text-center">
                        <PhotoIcon
                          className="mx-auto h-12 w-12 text-gray-300"
                          aria-hidden="true"
                        />
                        <div className="mt-4 flex text-sm leading-6 text-primary">
                          <label
                            htmlFor="fileName"
                            className="relative flex cursor-pointer rounded-md bg-white font-semibold text-focus:ring-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-focus:ring-primary focus-within:ring-offset-2 hover:text-primary-300"
                          >
                            <span>Upload a file</span>
                            <input
                              id="fileName"
                              name="fileName"
                              type="file"
                              className="sr-only"
                              accept="application/pdf"
                              onChange={(e) => {
                                const data = e.target.files
                                const newData = Array.from(data)
                                const updatedFiles = [...files, ...newData]
                                setValues({
                                  ...values,
                                  files: updatedFiles,
                                })
                              }}
                              multiple
                            />
                            <p className="pl-1">or drag and drop</p>
                          </label>
                        </div>
                        <p className="text-xs leading-5 flex w-full items-center justify-center gap-3 text-gray-600">
                          {values.files && values.files.length > 0 ?
                            <p>{values.files.map((s) => <span className="flex gap-2"> <p>{s.name}</p>
                              <button type='button'
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    files: values.files.filter((e) => e !== s)
                                  })
                                }}

                                className="cursor-pointer text-red-500 px-1 py-1 hover:bg-red-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                              ><XMarkIcon className="w-4" />
                              </button>
                            </span>)}</p>
                            : "PDF"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-2">
                  <div className="mt-4 sm:mt-6">
                    <Button
                      type="button"
                      className={'bg-red-500 hover:bg-red-700'}
                      onClick={() => setModal((prev) => ({ ...prev, state: false }))}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="mt-4 sm:mt-6">
                    <Button
                      type="submit"
                      loading={loading}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </div >
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  const renderCRMModal = () => {
    const { type, state, edit_id, data } = crmModal
    console.log(crmModal)
    return (
      <Formik
        initialValues={data}
        validationSchema={servicesTypeClassesSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          delete values.pdfId
          values.pdfId = exams?.map((e) => e.naplanExamId)
            app_api
              .patch(`crm-service/${edit_id}`, {
                name: values.name,
                cost: parseInt(values.cost),
                grade: parseInt(values.grade),
                sessionType: values.sessionType,
                description: values.description,
                archive: enabledSub,
                typeOf: values.typeOf,
                pdfId: values.pdfId,
              })
              .then((res) => {
                getServiceData()
                setCrmModal((prev) => ({ ...prev, state: false }))
                toast.success("Successfully updated")
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                setError(err.toString)
              })
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            title={'CRM Service'}
            open={state}
            setOpen={() => {
              setCrmModal((prev) => ({ ...prev, state: false }))
            }}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 flex flex-wrap mt-4'>
                <div className="col-span-2 lg:mt-4">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="name"
                    label="Name"
                    value={values.name}
                    placeholder="Enter Name"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={() => setFieldTouched('name', false)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {touched.name && (
                    <p className="text-red-700 error_msg">{errors.name}</p>
                  )}
                </div>

                {/* Classes */}
                {values.name != 'Admin Charge' && (
                  <>
                    <div className="col-span-2 lg:mt-4">

                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Grade
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>

                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="grade"
                        id="grade"
                        isSearchable
                        loadingMessage="Getting Exam..."
                        placeholder="Select a Grade"
                        value={
                          values.grade
                            ? {
                              value: grade?.find((e) => e.id == values.grade)?.id,
                              label: grade?.find((e) => e.id == values.grade)
                                ?.grade,
                            }
                            : null
                        }
                        options={grade.map((l) => ({
                          ...l,
                          label: l.grade,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            grade: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        onFocus={() => setFieldTouched('grade', false)}
                      />
                      {touched.grade && (
                        <p className="text-red-700 error_msg ">{errors.grade}</p>
                      )}
                    </div>

                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Type of Service
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>

                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="typeOf"
                        id="typeOf"
                        isSearchable
                        loadingMessage="Getting Group Size..."
                        placeholder="Select a type of service..."
                        value={
                          values.typeOf
                            ? typeOfServiceOptions?.map((l) => ({
                              value: typeOfServiceOptions?.find(
                                (e) => e.id == values.typeOf
                              )?.id,
                              label: typeOfServiceOptions?.find(
                                (e) => e.id == values.typeOf
                              )?.name,
                            }))
                            : null
                        }
                        options={typeOfServiceOptions.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            typeOf: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        onFocus={() => setFieldTouched('typeOf', false)}
                      />
                      {touched.typeOf && (
                        <p className="text-red-700 error_msg ">
                          {errors.typeOf}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">

                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Group Size
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>

                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="sessionType"
                        id="sessionType"
                        isSearchable
                        loadingMessage="Getting Group Size..."
                        placeholder="Select a Group Size..."
                        value={
                          values.sessionType
                            ? groupSizeOptions?.map((l) => ({
                              value: groupSizeOptions?.find(
                                (e) => e.id == values.sessionType
                              )?.id,
                              label: groupSizeOptions?.find(
                                (e) => e.id == values.sessionType
                              )?.name,
                            }))
                            : null
                        }
                        options={groupSizeOptions.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            sessionType: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        onFocus={() => setFieldTouched('sessionType', false)}
                      />
                      {touched.sessionType && (
                        <p className="text-red-700 error_msg ">
                          {errors.sessionType}
                        </p>
                      )}
                    </div>
                  </>
                )}

                <div className="col-span-2 lg:mt-4">

                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Cost
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>

                  <CurrencyInput
                    id="cost"
                    name="cost"
                    placeholder="Please enter cost amount"
                    decimalsLimit={2}
                    value={values.cost}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    prefix="$"
                    onValueChange={(value) => {
                      setValues({
                        ...values,
                        cost: value,
                      })
                    }}
                    onFocus={() => setFieldTouched('cost', false)}
                  />
                  {touched.cost && (
                    <p className="text-red-700 error_msg ">{errors.cost}</p>
                  )}
                </div>
                <div className='lg:mt-10 col-span-2'>
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Service Status
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={enabledSub}
                      onChange={setEnabledSub}
                      className={classNames(
                        enabledSub ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabledSub ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <div className="col-span-2 lg:mt-4">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    placeholder="Please enter the description"
                    name="description"
                    rows={4}
                    cols={40}
                    value={values.description}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(event) => {
                      setValues({
                        ...values,
                        description: event.target.value,
                      })
                    }}
                    onFocus={() => setFieldTouched('description', false)}
                  ></textarea>
                </div>

                {values.typeOf == "Workshop" && (
                  <>
                    <div className="w-full col-span-2 bg-gray-50 p-6 rounded-md shadow-md">
                      <h2 className="text-2xl font-bold text-gray-900 flex justify-center mt-2">Uploads</h2>
                      <div
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          e.preventDefault();
                          const data = e.target.files;
                          const newData = Array.from(data);
                          const updatedFiles = [...files, ...newData];
                          setValues({
                            ...values,
                            files: updatedFiles,
                          });
                        }}
                        className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                      >
                        <div className="text-center">
                          <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                          <div className="mt-4 flex text-sm leading-6 text-primary">
                            <label
                              htmlFor="fileName"
                              className="relative flex cursor-pointer rounded-md bg-white font-semibold text-focus:ring-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-focus:ring-primary focus-within:ring-offset-2 hover:text-primary-300"
                            >
                              <span>Upload a file</span>
                              <input
                                id="fileName"
                                name="fileName"
                                type="file"
                                className="sr-only"
                                onChange={(e) => {
                                  const data = e.target.files;
                                  const newData = Array.from(data);
                                  const updatedFiles = [...files, ...newData];
                                  console.log(updatedFiles)
                                  setValues({
                                    ...values,
                                    files: updatedFiles,
                                  });
                                }}
                                multiple
                              />
                              <p className="pl-1">or drag and drop</p>
                            </label>
                          </div>
                          <p className="text-xs leading-5 flex w-full items-center justify-center gap-3 text-gray-600">
                            {values.files && values.files.length > 0 ? (
                              <p>
                                {values.files.map((s) => (
                                  <span className="flex gap-2">
                                    <p>{s.name}</p>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setValues({
                                          ...values,
                                          files: values.files.filter((e) => e !== s),
                                        });
                                      }}
                                      className="cursor-pointer text-red-500 px-1 py-1 hover:bg-red-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                                    >
                                      <XMarkIcon className="w-4" />
                                    </button>
                                  </span>
                                ))}
                              </p>
                            ) : (
                              "PDF"
                            )}
                          </p>
                        </div>
                      </div>
                      {type == "edit" && values.typeOf === "Workshop" && (
                        <div className="flex justify-end mt-1">
                          <Button
                            type={'button'}
                            onClick={() => uploadedFiles(values.id, values.files)}
                            className='w-1/3 flex gap-2'><ArrowUpCircleIcon className='w-5' /> Upload</Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {type === 'edit' && values.typeOf === "Workshop" && (
                <div className="mt-4 rounded-lg overflow-auto max-h-[400px] overflow-y-auto">
                  <h2 className="font-bold text-lg">Exam Files</h2>
                  <table className="min-w-full divide-y divide-gray-300 py-2 align-middle sm:px-6 lg:px-8">
                    <thead className="bg-gray-50 top-0 z-10">
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                          File name
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                          <span className="px-2">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-gray-50 divide-y divide-gray-500 ">
                      {exams.map((s) => (
                        <tr key={s.id}>
                          <td className="px-4 py-3 text-sm">
                            {s.pdf.fileName}
                          </td>
                          <td className="px-4 py-3 text-sm ">
                            <button type='button'
                              onClick={() => {
                                const filteredExam = exams.filter((l) => l.id !== s.id);
                                setValues({
                                  ...values,
                                  exam: filteredExam,
                                });
                                setExams(filteredExam)
                              }}
                              className="cursor-pointer text-red-500 px-2 py-1 hover:bg-red-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                            ><TrashIcon className="w-5" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="mt-3 sm:mt-6">
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  return (
    <>
      <Sidebar>
        <ConfirmationDialog
          setOpen={setConfirmationDialog}
          open={confirmationDialog}
          onDelete={onDeleteService}
          confirmationButtonText="Delete"
          description='Do you really want to delete this data?'
        />
        {renderModal()}
        {renderCRMModal()}
        <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
          <div className="sm:flex sm:items-center py-2">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-semibold text-gray-900">
                Workshop PDF Files
              </h1>
              {/* <p className="my-2 text-sm text-gray-700">
                A list of all the naplan exams pdf.
              </p> */}
              <nav className="flex mt-4 " aria-label="Breadcrumb">
                <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
                  <li className="flex">
                    <div className="flex items-center">
                      <Link to="/setting" className="text-gray-400 hover:text-gray-500 flex items-center gap-2">
                        <Cog8ToothIcon className="h-5 w-5 flex-shrink-0 " aria-hidden="true" />
                        <span className="">Setting</span>
                      </Link>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex items-center">
                      <svg
                        className="h-full w-6 flex-shrink-0 text-gray-200"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      <Link
                        to={'/setting/naplan'}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current='page'
                      >
                        Workshop
                      </Link>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                onClick={() =>
                  setModal((prev) => ({ ...prev, type: 'add', state: true }))
                }
                className="flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mt-0 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Add
              </Button>
            </div>
          </div>

          {loading ? (
            <div className="flex items-center justify-center">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) :
            <>
              <Table
                progPage={page}
                totalCount={count}
                columns={naplan_columns({ onDeleteOpen,serviceOpen })}
                data={tabData}
                onEditClose={cleanModalData}
                setSearchFilter={setSearchFilter}
              />
              <nav
                className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <select
                      onChange={(e) => {
                        setLimit(e.target.value)
                        setPage(0)
                      }}
                      value={limit}
                    >
                      <option value={count}>{count}</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>{' '}
                    of <span className="font-medium">{count}</span> results
                  </p>
                </div>
                <div className="flex items-center md:justify-end">
                  <span
                    onClick={goPrev}
                    className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Previous
                  </span>
                  <span className="text-sm">
                    Page{' '}
                    <input
                      className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                      type="number"
                      value={page + 1}
                      onChange={(e) => setPage(e.target.value - 1)}
                      max={totalPages}
                      min="1"
                    />
                    <span className='ml-2'></span>/ {Math.ceil(totalPages)}
                  </span>
                  <span
                    onClick={goNext}
                    className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Next
                  </span>
                </div>
              </nav>
            </>}
        </div>
        <ToastContainer
          closeButton={false}
          closeOnClick={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          autoClose={2000}
          hideProgressBar={false} />
      </Sidebar>
    </>
  )

}

export default UploadedFiles