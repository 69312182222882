import { useState, useEffect } from 'react'
import app_api from '../../config/api'
import { Sidebar } from '../../components/navigation/sidebar'
import { AcademicCapIcon, ArrowLeftCircleIcon, CheckCircleIcon, XCircleIcon, } from '@heroicons/react/24/outline'
import moment from 'moment'
import { Button } from '../../components/tables/paginationButtons'
import { classNames } from '../../helpers/classname'


const MyChild = () => {
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState([])
    const [classId, setClassId] = useState()
    const [session, setSession] = useState()
    const [studentId, setStudentId] = useState()
    const [tab, setTab] = useState('Today')

    const getStudentSchedule = () => {
        let email = JSON.parse(localStorage.getItem('moldKey')).email
        let url = `family-master/schedule/${email}`
        app_api
            .get(url)
            .then((res) => {
                setLoading(false)
                if (res.data.studentMaster.length <= 1) {
                    setStudentId(res.data.studentMaster[0].id)
                    getSessionDatat(res.data.studentMaster[0].id)
                }
                setCategory(res.data.studentMaster)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const getSessionDatat = (id) => {
        let url = `student-attendance-master/findall/${id}`
        app_api
            .get(url)
            .then((res) => {
                setLoading(false)
                setSession(res.data)
            })
            .catch((err) => {
                setLoading(false)
            })
    }
 
    useEffect(() => {
        getStudentSchedule()
    }, [])
    return (
        <Sidebar>
            <div className="p-4 flex flex-col min-h-screen">
                <div className="px-2 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-lg font-semibold text-gray-900 dashboardTitle">
                                My Child
                            </h1>
                        </div>
                    </div>
                    {loading ? (
                        <div className="flex items-center justify-center">
                            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col lg:flex-row gap-2 my-4 ">
                            <div className="lg:w-5/12 w-full">
                                <h className='font-bold text-lg text-indigo-600'>Child</h>
                                <div className="flex flex-col space-y-2 bg-gray-100 w-full lg:h-[800px] shadow-md rounded-lg">
                                    <div className='p-2 space-y-2'>
                                        {category.map((s) => (
                                            <>
                                                <div className='flex justify-between bg-gray-50 shadow-md rounded-md md:p-2'>
                                                    <div className='flex flex-col'>
                                                        <span className='font-bold text-md p-2'>
                                                            {s.studentsFirstName + ' ' + s.studentsLastName}
                                                        </span>
                                                        <div className='flex justify-between gap-2 p-2'>
                                                            <span className='flex'><AcademicCapIcon className='w-6 mr-2' />{s.class.length} </span>
                                                            <span className='flex'><CheckCircleIcon className='w-6 mr-2' />{s.attendance.filter(s => s.attendance === 1).length} </span>
                                                            <span className='flex'><XCircleIcon className='w-6 mr-2' />{s.attendance.length} </span>
                                                        </div>
                                                    </div>
                                                    {studentId == s.id ?
                                                        <div className='flex justify-between items-center'>
                                                            <Button onClick={() => { setStudentId(); setClassId(); setTab('Today') }} className={`bg-red-600 hover:bg-red-700`} >Close</Button>
                                                        </div>
                                                        : <div className='flex justify-between items-center'>
                                                            <Button onClick={() => { getSessionDatat(s.id); setStudentId(s.id); setClassId(); setTab('Today') }} >View</Button>
                                                        </div>}
                                                </div>
                                                <hr class="h-px my-2 mx-0 bg-gray-500 border-0" />
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {session?.length > 0 && studentId != null ? (
                                <div className="w-full">
                                    <h className='font-bold text-lg text-indigo-600'>Session</h>
                                    <div className="flex flex-col lg:flex-row bg-gray-100 p-2 w-full justify-between gap-4 lg:h-[800px] shadow-md rounded-lg">
                                        <div className="w-full">
                                            <div className=" overflow-x-auto overflow-y-auto max-h-screen lg:max-h-[700px]">
                                                <div className="flex flex-row">
                                                    {/* <div>
                                                        <ArrowLeftCircleIcon className="w-6 mt-2 cursor-pointer text-indigo-600" onClick={() => { setStudentId(); setTab('Today') }} />
                                                    </div> */}
                                                    <div className="flex justify-between w-full px-4">
                                                        <span
                                                            className={classNames(
                                                                tab == 'Today'
                                                                    ? 'bg-indigo-800 text-white '
                                                                    : 'transition duration-300',
                                                                'rounded-md p-2 cursor-pointer hover:bg-indigo-900 hover:text-white'
                                                            )}
                                                            onClick={() => {
                                                                setTab('Today')
                                                            }}
                                                        >
                                                            Today's Sessions
                                                        </span>
                                                        <span
                                                            className={classNames(
                                                                tab == 'Upcoming'
                                                                    ? 'bg-indigo-800 text-white '
                                                                    : 'transition duration-300',
                                                                'rounded-md p-2 cursor-pointer hover:bg-indigo-900 hover:text-white'
                                                            )}
                                                            onClick={() => {
                                                                setTab('Upcoming')
                                                            }}
                                                        >
                                                            Upcoming Sessions
                                                        </span>
                                                        <span
                                                            className={classNames(
                                                                tab == 'Previous'
                                                                    ? 'bg-indigo-800 text-white '
                                                                    : 'transition duration-300',
                                                                'rounded-md p-2 cursor-pointer hover:bg-indigo-900 hover:text-white'
                                                            )}
                                                            onClick={() => {
                                                                setTab('Previous')
                                                            }}
                                                        >
                                                            Previous Sessions
                                                        </span>
                                                    </div>
                                                </div>
                                                <table className="min-w-full divide-y divide-gray-300 py-2 align-middle sm:px-6 lg:px-8 px-2 md:px-4 table-auto lg:overflow-x-auto">
                                                    <thead className="sticky top-0 bg-gray-200">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="px-4 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                                                            >
                                                                Subject
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-4 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                                                            >
                                                                Date
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-4 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                                                            >
                                                                Time
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-4 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                                                            >
                                                                Attended / Not
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-gray-50 divide-y divide-gray-500">
                                                        {tab == 'Previous' && session.filter(e => moment(e.session.start_time).isBefore(moment(), 'day'))?.map((s) => {
                                                            let previousSessions = []
                                                            if(moment(s.session.start_time).isBefore(moment(), 'day')) previousSessions.push(s)
                                                            previousSessions.sort((a, b) => moment(b.session.date) - moment(a.session.date));
                                                            return previousSessions.length > 0 &&
                                                                previousSessions.map((index) => (
                                                                    <tr key={index.id}>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            <span className="cursor-pointer">
                                                                                {index.session.class.subject.subject}
                                                                            </span>
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            {moment(index.session.start_time).format('DD-MM-YYYY')}
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            {moment(index.session.start_time).format('h:mm A')} - {moment(index.session.end_time).format('h:mm A')}
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs text-center">
                                                                            {index.attendance == 1 ? <span className="text-green-700"><CheckCircleIcon className="w-6 " /></span> : <span className="text-red-600"><XCircleIcon className="w-6 " /></span>}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                
                                                        })}

                                                        {tab == 'Previous' && session.filter(e => moment(e.session.start_time).isBefore(moment(), 'day')).length == 0 && (
                                                            <>
                                                            <tr>
                                                                    <td className="px-4 py-3 md:text-base text-xs">
                                                                        <span className="cursor-pointer">
                                                                            No previous sessions
                                                                        </span>
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        )}

                                                        {tab == 'Today' && session.filter(e => moment(e.session?.start_time).isSame(moment(), 'day')).map((s) => {
                                                            let todaySessions = []
                                                            if(moment(s.session?.start_time).isSame(moment(), 'day')) todaySessions.push(s);
                                                            todaySessions.sort((a, b) => moment(a.session.start_time) - moment(b.session.start_time)); 
                                                            return todaySessions.length > 0 &&
                                                                todaySessions.map((index) => (
                                                                    <tr key={index.id}>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            <span className="cursor-pointer">
                                                                                {index.session.class.subject.subject}
                                                                            </span>
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            {moment(index.session?.start_time).format('DD-MM-YYYY')}
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            {moment(index.session?.start_time).format('h:mm A')} - {moment(index.session?.end_time).format('h:mm A')}
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs text-center">
                                                                            {index.attendance == 1 ? <span className="text-green-700"><CheckCircleIcon className="w-6 " /></span> : <span className="text-red-600"><XCircleIcon className="w-6 " /></span>}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                
                                                        })}
                                                        {tab == 'Today' && session.filter(e => moment(e.session?.start_time).isSame(moment(), 'day')).length == 0 && (
                                                            <>
                                                            <tr>
                                                                    <td className="px-4 py-3 md:text-base text-xs">
                                                                        <span className="cursor-pointer">
                                                                            No session for today
                                                                        </span>
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        )}

                                                        {tab == 'Upcoming' && session.filter(e => moment(e.session.start_time).isAfter(moment(), 'day')).map((s) => {
                                                            let upcomingSessions = []
                                                            if(moment(s.session.start_time).isAfter(moment(), 'day')) upcomingSessions.push(s);
                                                            upcomingSessions.sort((a, b) => moment(a.session.start_time) - moment(b.session.start_time));
                                                            return upcomingSessions.length > 0 &&
                                                                upcomingSessions.map((index) => (
                                                                    <tr key={index.id}>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            <span className="cursor-pointer">
                                                                                {index.session.class.subject.subject}
                                                                            </span>
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            {moment(index.session?.start_time).format('DD-MM-YYYY')}
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs">
                                                                            {moment(index.session?.start_time).format('h:mm A')} - {moment(index.session?.end_time).format('h:mm A')}
                                                                        </td>
                                                                        <td className="px-4 py-3 md:text-base text-xs text-center">
                                                                            {index.attendance == 1 ? <span className="text-green-700"><CheckCircleIcon className="w-6 " /></span> : <span className="text-red-600"><XCircleIcon className="w-6 " /></span>}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        })}

                                                        {tab == 'Upcoming' && session.filter(e => moment(e.session.start_time).isAfter(moment(), 'day')).length == 0 && (
                                                            <>
                                                            <tr>
                                                                    <td className="px-4 py-3 md:text-base text-xs">
                                                                        <span className="cursor-pointer">
                                                                            All sessions are completed
                                                                        </span>
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="flex flex-col lg:w-1/5 lg:border-l-2">
                                            <div className="p-2 flex flex-col gap-4">
                                                <div className='flex justify-between p-2 shadow-md rounded-md gap-8 bg-gray-50'>
                                                    <span >Total  </span>
                                                    <span className="md:text-lg font-medium text-xs">{session.length} </span>
                                                </div>
                                                <div className='flex justify-between p-2 shadow-md rounded-md gap-8 bg-gray-50'>
                                                    <span >Attended  </span>
                                                    <span className="md:text-lg font-medium text-xs">{session.filter(e => e.attendance == 1).length} </span>
                                                </div>
                                                <div className='flex justify-between p-2 shadow-md rounded-md gap-8 bg-gray-50'>
                                                    <span >Not Attended  </span>
                                                    <span className="md:text-lg font-medium text-xs">{session.filter(e => e.attendance == 0 && moment(e.session.date).isBefore(moment(), 'day')).length} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full">
                                    <h className='font-bold text-lg text-indigo-600'>Sessions</h>
                                    <div className="flex bg-gray-100 w-full h-[800px] shadow-md rounded-lg justify-center items-center">
                                        <h className='font-bold text-lg text-indigo-600'>Please Select a Child</h>
                                    </div>
                                </div>)
                            }
                        </div>
                    )}
                </div>
            </div>
        </Sidebar>
    )
}

export default MyChild
