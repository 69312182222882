import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { login_session_columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { categorySchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    category: '',
  },
}

const LoginSession = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  useEffect(() => {
    getCategoryData()
  }, [page, limit, searchFilter])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .delete(`/login-session/${id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const getCategoryData = () => {
  let url = `login-session/all?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        if(res.count <= limit) setPage(0)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
        setCategory(data)
        setTabData(data)
        if(isNaN(res.count / limit)){setTotalPages(1)}else{setTotalPages(res.count / limit)}
        setCount(res.count)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getCategoryData()
  }, [])


  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const { category } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={categorySchema}
        enableReinitialize
        onSubmit={(values, {setSubmitting}) => {
          if (type === 'add') {
            app_api
              .post('/category', values)
              .then((res) => {
                cleanModalData()
                getCategoryData()
                setSubmitting(false)
              })
              .catch((err) => {
                if(err.response.status == 424){
                  toast.error('Duplicate Entry')
                }
                else{
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/category/${edit_id}`, values)
              .then((res) => {
                getCategoryData()
                cleanModalData()
                setSubmitting(false)
              })
              .catch((err) => {})
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add Category' : 'Edit Category'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <Input
                  name="category"
                  label="Category"
                  value={values.category}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Category..."
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                {touched.category && (
                  <p className="text-red-700 error_msg">{errors.category}</p>
                )}
                <div className="mt-4 sm:mt-6">
                  <Button type="submit"
                  disabled={isSubmitting}
                    // onClick={() => {
                    //   type === 'add'
                    //     ? createCategory()
                    //     : updateCategory(edit_id)
                    // }}
                  >
                    {isSubmitting ?  (type === 'add' ? 'Adding...' : 'Saving...') : (type === 'add' ? 'Add Category' : 'Update Category')}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
    <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Login Session</h1>
          </div>
        </div>
        {/* <Table
          columns={login_session_columns({ onDeleteOpen })}
          data={category}
          className="-striped -highlight"
        /> */} 
        {loading ? (
          <div className="flex items-center justify-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) :
        <>        
          <Table
          progPage={page}
          totalCount={count}
          columns={login_session_columns({ onDeleteOpen })}
          data={
            TabData
          }
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
        <nav
        className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <select
              onChange={(e) => {
                setLimit(e.target.value)
                setPage(0)
              }}
              value={limit}
            >
              <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>{' '}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        <div className="flex items-center md:justify-end">
          <span
            onClick={goPrev}
            className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Previous
          </span>
          <span className="text-sm">
            Page{' '}
            <input
              className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
              type="number"
              value={page + 1}
              onChange={(e) => setPage(e.target.value - 1)}
              max={totalPages}
              min="1"
            />
            <span className='ml-2'></span>/ {Math.ceil(totalPages)}
          </span>
          <span
            onClick={goNext}
            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Next
          </span>
        </div>
      </nav>
      </>}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default LoginSession
