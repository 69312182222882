import { Formik } from 'formik'
import React from 'react'
import { locationSchema } from '../../schema'
import { toast } from 'react-toastify'
import app_api from '../../config/api'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import Select from 'react-select'
import Button from '../../components/button'
import BigSlideover from '../../components/big-slideover'

export default function EditLocationModal(props) {
  const { type, state, edit_id, data } = props.locationModal
  const {
    gstValue,
    abnStatus,
    entityName,
    getLocationData,
    cleanModalData,
    location,
    modal,
    setLocation,
    setLocationModal,
    LocationManager,
    studentCity,
    studentState,
    getCities,
    checkPostCode,
    postCodeError,
    defaultState,
    getAbnValue,
    abnError,
  } = props
  return (
    <Formik
      initialValues={data}
      validationSchema={locationSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (type === 'add') {
          app_api
            .post('/location', {
              ...values,
              gst: gstValue,
              abnStatus: abnStatus,
              entityName: entityName,
            })
            .then((res) => {
              getLocationData()
              cleanModalData()
              setSubmitting(false)
              toast.success('Successfully added location')
              resetForm()
            })
            .catch((err) => {
              toast.error(err.response.data.message)
              getLocationData()
              // cleanModalData()
              setSubmitting(false)
            })
        } else {
          app_api
            .patch(`/location/${edit_id}`, { ...values, gst: gstValue })
            .then((res) => {
              let updatedLocationData = [...location]
              updatedLocationData[modal.index] = modal.data
              setLocation(updatedLocationData)
              getLocationData()
              toast.success('Successfully updated location')
              cleanModalData()
              setSubmitting(false)
              resetForm()
            })
            .catch((err) => {
              toast.error(err.response.data.message)
            })
        }
      }}
    >
      {({
        handleBlur,
        Formik,
        handleChange,
        handleSubmit,
        setValues,
        setFieldTouched,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
        resetForm,
      }) => (
        <BigSlideover
          open={state}
          setOpen={() => {
            setLocationModal((prev) => ({ ...prev, state: false }))
          }}
          title={'Location'}
          handleClick={''}
          data={''}
        >
          <form onSubmit={handleSubmit} noValidate>
            <div className="text-left mt-4">
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 w-full">
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="name"
                    label="Name"
                    value={values.name}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Name..."
                    onFocus={() => setFieldTouched('name', false)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {touched.name && (
                    <p className="text-red-700 mb-1 error_msg">{errors.name}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Point of Contact
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Select
                    className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="pointOfContact"
                    id="pointOfContact"
                    isSearchable
                    loadingMessage="Getting User..."
                    placeholder="Select a user..."
                    value={
                      values.pointOfContact
                        ? LocationManager?.map((l) => ({
                            value: LocationManager?.find(
                              (e) => e.name == values.pointOfContact
                            )?.id,
                            label: LocationManager?.find(
                              (e) => e.name == values.pointOfContact
                            )?.name,
                          }))
                        : null
                    }
                    options={LocationManager.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) => {
                      console.log(option)
                      setValues({
                        ...values,
                        pointOfContact: option.value,
                        userId: option.id,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    onFocus={() => setFieldTouched('city', false)}
                    maxMenuHeight={110}
                  />
                  {touched.city && (
                    <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Phone / Mobile Number
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="phoneNumber"
                    label="Mobile / Phone Number"
                    placeholder="Enter Mobile / Phone Number"
                    type="tel"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={values.phoneNumber}
                    autoComplete="off"
                    onFocus={() => setFieldTouched('phoneNumber', false)}
                    onBlur={(e) => {
                      setValues({
                        ...values,
                        phoneNumber: e.target.defaultValue,
                      })
                    }}
                    onChange={handleChange}
                  />
                  {touched.phoneNumber && (
                    <p className="text-red-700 mb-2 error_msg">
                      {errors.phoneNumber}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="emailAddress"
                    label="Email Address"
                    type="text"
                    value={values.emailAddress}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={() => setFieldTouched('emailAddress', false)}
                    placeholder="Enter Email Address..."
                  />
                  {touched.emailAddress && (
                    <p className="text-red-700 error_msg">
                      {errors.emailAddress}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Address Line 1
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="address"
                    label="Address"
                    type="text"
                    value={values.address}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={() => setFieldTouched('address', false)}
                    placeholder="Enter Address..."
                  />
                  {touched.address && (
                    <p className="text-red-700 error_msg">{errors.address}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      City
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Select
                    className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="studentCity"
                    id="studentCity"
                    isSearchable
                    loadingMessage="Getting City..."
                    placeholder="Select City..."
                    value={
                      values.city
                        ? studentCity?.map((l) => ({
                            value: studentCity?.find(
                              (e) => e.name == values.city
                            )?.isoCode,
                            label: studentCity?.find(
                              (e) => e.name == values.city
                            )?.name,
                          }))
                        : null
                    }
                    options={studentCity.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        city: option.value,
                        postCode: '',
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={() => setFieldTouched('city', false)}
                  />
                  {touched.city && (
                    <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      State
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="state"
                    id="state"
                    isSearchable
                    loadingMessage="Getting State..."
                    placeholder="Select a State..."
                    value={
                      values.state
                        ? studentState?.map((l) => ({
                            value: studentState?.find(
                              (e) => e.name == values.state
                            )?.isoCode,
                            label: studentState?.find(
                              (e) => e.name == values.state
                            )?.name,
                          }))
                        : defaultState
                        ? studentState?.map((l) => ({
                            value: studentState?.find(
                              (e) => e.name == defaultState[0].name
                            )?.isoCode,
                            label: studentState?.find(
                              (e) => e.name == defaultState[0].name
                            )?.name,
                          }))
                        : null
                    }
                    options={studentState.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) => {
                      getCities(option.countryCode, option.isoCode)
                      setValues({
                        ...values,
                        state: option.value,
                      })
                      checkPostCode(values.postCode, option.value)
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={() => setFieldTouched('state', false)}
                  />
                  {touched.state && (
                    <p className="text-red-700 error_msg">{errors.state}</p>
                  )}
                </div>
                <div className=''>
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Postcode
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="postCode"
                    label="postCode"
                    type="text"
                    value={values.postCode}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        postCode: e.target.value,
                      })
                      checkPostCode(e.target.value, values.state)
                    }}
                    placeholder="Enter postcode"
                    maxLength={4}
                    onFocus={() => setFieldTouched('postCode', false)}
                  />
                  {touched.postCode && (
                    <p className="text-red-700 error_msg">{errors.postCode}</p>
                  )}
                  {postCodeError && (
                    <p className="text-red-700 error_msg">{postCodeError}</p>
                  )}
                </div>
                <div className=''>
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Account Name
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="accountName"
                    label="Account Name"
                    type="text"
                    value={values.accountName}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Account Name..."
                    onFocus={() => setFieldTouched('accountName', false)}
                  />
                  {touched.accountName && (
                    <p className="text-red-700 error_msg">
                      {errors.accountName}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      BSB
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="BSB"
                    label="BSB"
                    type="text"
                    value={values.BSB}
                    autoComplete="off"
                    maxLength="6"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Bank BSB..."
                    onFocus={() => setFieldTouched('BSB', false)}
                  />
                  {touched.BSB && (
                    <p className="text-red-700 error_msg">{errors.BSB}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Account Number
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="accountNumber"
                    label="Account Number"
                    type="text"
                    value={values.accountNumber}
                    autoComplete="off"
                    maxLength="11"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Bank Account Number..."
                    onFocus={() => setFieldTouched('accountNumber', false)}
                  />
                  {touched.accountNumber && (
                    <p className="text-red-700 error_msg">
                      {errors.accountNumber}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Google My Business
                    </label>
                    {/*<span className='text-red-700 ml-1'>*</span>*/}
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="googleurl"
                    label="Account Number"
                    type="url"
                    value={values.googleurl}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="https://www.google.com/"
                    onFocus={() => setFieldTouched('googleurl', false)}
                  />
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Facebook
                    </label>
                    {/*<span className='text-red-700 ml-1'>*</span>*/}
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="facebook"
                    label="Account Number"
                    type="url"
                    value={values.facebook}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={() => setFieldTouched('facebook', false)}
                    placeholder="https://www.facebook.com/"
                  />
                </div>
                <div className=''>
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      ABN
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <div className="flex">
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="abn"
                      type="text"
                      value={values.abn}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('abn', false)}
                      placeholder="Enter Australian Business Number..."
                    />
                    <p
                      className="w-8 rounded-r-md border-y border-r border-gray-300  py-1 appearance-none "
                      onClick={() => getAbnValue(values.abn)}
                    >
                      <MagnifyingGlassIcon className="w-full drop-shadow-md" />
                    </p>
                  </div>
                  {touched.abn && (
                    <p className="text-red-700 error_msg">{errors.abn}</p>
                  )}
                  {abnError && (
                    <p className="text-red-700 error_msg">{abnError}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      GST Register Date
                    </label>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="gst"
                    label="GST"
                    type="text"
                    value={values.gstValue}
                    autoComplete="off"
                    onBlur={handleBlur}
                    disabled
                    // onChange={handleChange}
                    placeholder={
                      gstValue ? moment(gstValue).format('DD MM YYYY') : null
                    }
                    onFocus={() => setFieldTouched('gst', false)}
                  />
                  {touched.gst && (
                    <p className="text-red-700 error_msg">{errors.gst}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      ABN Status
                    </label>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="abnStatus"
                    type="text"
                    value={values.abnStatus}
                    autoComplete="off"
                    onBlur={handleBlur}
                    disabled
                    // onChange={handleChange}
                    placeholder={abnStatus}
                  />
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Entity Name
                    </label>
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="entityName"
                    type="text"
                    value={values.entityName}
                    autoComplete="off"
                    onBlur={handleBlur}
                    disabled
                    placeholder={entityName}
                  />
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Xero API Key
                    </label>
                    {/* <span className='text-red-700 ml-1'>*</span> */}
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="xeroAPIKey"
                    label="Xero API Key"
                    type="text"
                    value={values.xeroAPIKey}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Xero API Key..."
                    onFocus={() => setFieldTouched('xeroAPIKey', false)}
                  />
                  {touched.xeroAPIKey && (
                    <p className="text-red-700 error_msg">
                      {errors.xeroAPIKey}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Xero Invoice Branding Theme ID
                    </label>
                    {/* <span className='text-red-700 ml-1'>*</span> */}
                  </div>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="brandingThemeID"
                    type="text"
                    value={values.brandingThemeID}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Xero Invoice Branding Theme ID..."
                    onFocus={() => setFieldTouched('brandingThemeID', false)}
                  />
                  {touched.brandingThemeID && (
                    <p className="text-red-700 error_msg">
                      {errors.brandingThemeID}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-4 sm:mt-6">
                <Button type="submit" disabled={isSubmitting || postCodeError}>
                  {isSubmitting
                    ? type === 'add'
                      ? 'Adding...'
                      : 'Saving...'
                    : type === 'add'
                    ? 'Add Location'
                    : 'Update Location'}
                </Button>
              </div>
            </div>
          </form>
        </BigSlideover>
      )}
    </Formik>
  )
}
