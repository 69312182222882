import React, { Fragment, useState, useEffect, useRef } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import logo from '../assets/icons/logo.png'
import { Button } from '../components/tables/paginationButtons'
import app_api from '../config/api'
import Countdown from 'react-countdown'
import { Dialog, Transition } from '@headlessui/react'
import {
  ExclamationTriangleIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline'
import moment from 'moment'
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from 'react-pro-sidebar'
import { ProSidebarProvider } from 'react-pro-sidebar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ExamQuestions = () => {
  const navigate = useNavigate()
  const param = useParams()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [exam, setExam] = useState({})
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState({})
  const [endTime, setEndTime] = useState(null)

  const [submitting, setSubmitting] = useState(false)
  const [submittingError, setSubmittingError] = useState(null)
  const [showCountdown, setShowCountdown] = useState()

  const [index, setIndex] = useState(0)
  const [open, setOpen] = useState(false)
  const [enrolment, setEnrolement] = useState([])

  useEffect(() => {
    getEnrolmentData()
    getExamDetails()
    getBatchid()
  }, [])

  const getEnrolmentData = () => {
    app_api.get(`/enrol-exam/${param?.enrolment_id}`).then((res) => {
      if (res.data.hasAttempted) {
        navigate('/my-exams')
      } else {
        try {
          let date = moment(res?.data?.expires_at).toDate()
          setEndTime(moment(date).toDate())
          setShowCountdown(true)
        } catch {
          setEndTime(moment().add(10, 'minutes').toDate())
          setShowCountdown(true)
        }
      }
      setEnrolement(res.data)
      setLoading(false)
    })
  }

  const getExamDetails = () => {
    app_api
      .get(`exam/${param.exam_id}`)
      .then((res) => {
        setLoading(false)
        setError(null)
        let { questions, ...exam } = res.data
        setExam(exam)
        setQuestions(res.data?.questions || [])
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'Error getting questions')
      })
  }

  const getBatchid = () => {
    app_api
      .get(`enrolment/${param.enrolment_id}`)
      .then((res) => {
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'Error getting questions')
      })
  }

  const NavigateTo = () => {
    const item = JSON.parse(localStorage.getItem('moldKey'))
    if (item) {
      navigate('/my-exams')
    } else {
      navigate('/')
    }
  }

  const selectAnswer = (qid, aid) => {
    setAnswers((prev) => ({
      ...prev,
      [qid]: aid,
    }))
  }

  const nextClick = () => {
    setSelectQuestionId((prev) => (prev < questions.length - 1 ? ++prev : prev))
  }

  const prevClick = () => {
    setSelectQuestionId((prev) => (prev > 0 ? --prev : prev))
  }

  function convert_from_sqrt_to_styled(text) {
    const pattern = /\$sqrt\((\d+)\)/g;

    const replacedText = text?.replace(pattern, (match, number) => {
      return `<span style="white-space: nowrap; font-size:larger">
              &radic;<span style="text-decoration:overline;">&nbsp;${number}&nbsp;</span>
            </span>`;
    });
    return replacedText;
  }

  const submitQuiz = () => {
    setSubmitting(true)
    let values = []
    questions.map((l) => {
      if (Object.keys(answers).find((i) => i == l.id)) {
        values.push({ question_id: parseInt(l.id), answer_id: answers[l.id] })
      } else {
        values.push({ question_id: parseInt(l.id), answer_id: null })
      }
    })
    app_api
      .post('user-response/bulk', {
        enrolExam_id: parseInt(param.enrolment_id),
        response: values,
      })
      .then((res) => {
        if (exam.previewMode) {
          navigate(`/my-exams/${param.enrolment_id}/${param.exam_id}/preview`)
        } else {
          navigate(`/my-exams`)
        }
      })
      .catch((err) => {
        setSubmittingError(
          err?.response?.data?.message || 'Error submitting your response'
        )
        toast.error("Something went wrong, Unable to submit your exam")
      })
  }

  const getTime = ({ days, hours, minutes, seconds, milliseconds, completed }) => {
    if (days == 0 && hours == 0 && minutes == 4 && seconds == 59) {
      setOpen(true)
    } else {
      if (days == 0 && hours == 0 && minutes == 0 && seconds == 0 && milliseconds == 0) {
        if(submitting == false){
          submitQuiz() 
        }
      } else {
        return (
          <span>
            {hours < 10 ? '0' + hours : hours}:{' '}
            {minutes < 10 ? '0' + minutes : minutes}:{' '}
            {seconds < 10 ? '0' + seconds : seconds}
          </span>
        )
      }
    }
  }
  const { collapseSidebar } = useProSidebar()
  const [selectquestionid, setSelectQuestionId] = useState(0)
const indexes = ['A', 'B', 'C', 'D', 'E', 'F', 'G','H','I','J','K','L'];
  function handleQuestion(e) {
    var id = e.currentTarget.getAttribute('data-reactid')
    setSelectQuestionId(id)
    // if (id !== e.currentTarget.getAttribute('data-reactid')) {
    //   document.getElementById(id).classList.add = 'active'
    // } else {
    //   document.getElementById(id).classList.remove = 'active'
    // }
  }
  return (
    <>
      <div className="border-b">
        <div className="flex items-center justify-between mr-8 py-2 fixed top-0 w-full bg-white border-b-2">
          <div>
            <img className="h-12 w-auto mx-8" src={logo} alt="Your Company" />
          </div>
          <div className=" font-bold text-capitalize">{exam?.name}</div>
          <div className="flex items-center font-bold mr-12">
            <AiOutlineClockCircle className="text-indigo-800 mr-1" />
            {endTime && <Countdown date={endTime} renderer={getTime} />}
          </div>
        </div>
      </div>
      <div className="flex disable-text-selection fontStandard">
        <main className="w-full">
          <div
            style={{
              display: '',
              marginTop: '7%',
              marginLeft: '1%',
            }}
          ></div>
          <div className="w-full md:w-10/12 mx-auto">
            {loading ? null : error !== null ? null : (
              <div className="mt-9 ml-8 w-full mx-auto mb-24">
                <div className="font-semibold">
                  Question {parseInt(selectquestionid) + 1}:
                </div>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: convert_from_sqrt_to_styled(questions[selectquestionid]?.question),
                  }}
                ></div>
                {questions[selectquestionid]?.answers.map((ans, idx) => (
                  <div
                    key={idx}
                    className="mt-4 w-full md:w-8/12 rounded-lg border flex items-center justify-start"
                    onClick={() =>
                      selectAnswer(questions[selectquestionid].id, ans.id)
                    }
                  >
                    <input
                      type="radio"
                      className="m-3"
                      id={ans.id}
                      name={questions[selectquestionid].id}
                      value={ans.id}
                      checked={
                        answers[questions[selectquestionid].id] === ans.id
                      }
                      onChange={() =>
                        selectAnswer(questions[selectquestionid].id, ans.id)
                      }
                    />
                    {indexes[idx] ? indexes[idx] : ''}:
                    <label
                      htmlFor={ans.id}
                      className="border-l w-full p-2 pl-4 ml-3"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: convert_from_sqrt_to_styled(ans.answer),
                        }}
                      ></span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className='flex justify-center w-full bg-gray-100 border-t-2 mt-10 py-3 fixed bottom-0'>
          <div className="flex justify- w-5/6">
              {parseInt(selectquestionid) === 0 ? (
                <></>
              ) : (
                <Button onClick={prevClick}>Previous</Button>
              )}
              {selectquestionid < questions?.length - 1 && (
                <span className={selectquestionid == 0 ? 'ml-0': 'ml-3'}>
                  <Button onClick={nextClick}>Next</Button>
                </span>
                
              )}
              {parseInt(selectquestionid) === questions?.length - 1 && (
                <Button onClick={submitQuiz} disabled={submitting}>
                  {submitting ? 'Submitting...' : 'Submit'}
                </Button>
              )}
          </div>
          </div>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                      <div>
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="leading-6 text-gray-900"
                          >
                            Notice
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-gray-500">
                              Only 5 Minutes are remaining
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </main>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </>
  )
}
export default ExamQuestions
