import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AcademicCapIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../button'
import logo from '../../assets/icons/logo.png'

import app_api from '../../config/api'

export default function ResetPasswordStudent(props) {
  const navigate = useNavigate()
  const [loading, setLoader] = useState(false)
  const {
    title = 'Welcome to NRICH Learning',
    description = 'As a new user we highly recommend to reset your password by clicking on the below button',
    confirmationButtonText = 'Reset Password',
    open,
    setOpen,
    onDelete,
    data,
  } = props

  const cancelButtonRef = useRef(null)

  const goToResetPassword = () => {
    navigate('/resetpassword')
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-4 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex flex flex-state deleteUI">
                  <div className="flex items-center justify-center flex-shrink-0 w-40 mx-auto rounded-full sm:mx-0 sm:w-40">
                    <img src={logo} className='w-64'/>
                  </div>
                  <div className="mt-3 text-center flex-grow sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="m-4">
                      <p className="text-m text-gray-500">{description}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-2 sm:mt-4 sm:flex deleteUI_button">
                  <Button
                    onClick={() => {
                      setLoader(true)
                      goToResetPassword()
                    }}
                    type="button"
                    loading={loading}
                    className="px-4 py-2 text-base font-medium text-white bg-green-700 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 mt-3 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                  >
                    {data?.expires_at
                      ? 'Reset your password'
                      : confirmationButtonText}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
