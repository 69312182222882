import { useState, useEffect, useCallback } from 'react'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { report_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom'
import { MdOutlineFileDownload } from 'react-icons/md'
import { Button } from '../components/tables/paginationButtons'
import { CiFilter } from 'react-icons/ci'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    category: '',
    categoryType: ''
  },
}

const familyStatus = [
  {
    id: 'Active',
    name: 'Active',
  },
  {
    id: 'Inactive',
    name: 'Inactive',
  },
]

const masterStatus = [
  {
    id: 'crm-invoice',
    name: 'Invoice'
  }, {
    id: "payment-master",
    name: 'Payment'
  }
]

const paymentType = [
  {
    id: 'Cash',
    name: 'CASH',
  },
  {
    id: 'Bank Transfer',
    name: 'BANK TRANSFER',
  },
  {
    id: 'Stripe',
    name: 'STRIPE',
  },
  {
    id: 'Credit Note',
    name: 'CREDIT NOTE',
  },
]

const invoiceStatusType = [
  {
    id: 'VOIDED',
    name: 'VOIDED',
  },
  {
    id: 'AWAITING PAYMENT',
    name: 'AWAITING PAYMENT',
  },
  {
    id: 'PAID',
    name: 'PAID',
  },
]

const Terms = [
  {
    id: 'Term 1',
    name: 'Term 1',
  },
  {
    id: 'Term 2',
    name: 'Term 2',
  },
  {
    id: 'Term 3',
    name: 'Term 3',
  },
  {
    id: 'Term 4',
    name: 'Term 4',
  },
]

const ReportPage = () => {
  const param = useParams()
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [categoryTypes, setCategoryTypes] = useState([])
  const [statusFilter, setStatusFilter] = useState(null)
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [locationFilter, setLocationFilter] = useState([])
  const [location, setLocation] = useState([])
  const [column, setColumn] = useState([])
  const [tabData, setTabData] = useState([])
  const [columnFilter, setColumnsFilter] = useState([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  const [types, setTypes] = useState(param.path)
  const [family, setFamily] = useState([])
  const [familyFilter, setFamilyFilter] = useState([])
  const [crmData, setCrmData] = useState([])
  const [serviceFilter, setServiceFilter] = useState([])
  const [termFilter, setTermFilter] = useState()
  const [allData, setAllData] = useState([])
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
    status: '',
    location: '',
    category: '',
    family: '',
    service: '',
    term: '',
  })
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false)
  let columnName = param.path
  let user_id = JSON.parse(localStorage.getItem('moldKey')).id
  let app
  let downloadApp
  if (param.path == 'family') {
    app = 'family-master'
    downloadApp = 'family-master'
  } else if (param.path == 'student') {
    app = 'family-invoice-item/student'
    downloadApp = 'family-invoice-item/student'
  } else if (param.path == 'invoice') {
    app = 'crm-invoice'
    downloadApp = 'crm-invoice'
  } else if (param.path == 'payment') {
    app = 'payment-master'
    downloadApp = 'payment-master'
  } else {
    app = 'location'
  }

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
      console.log('bottom', bottom,e.target.scrollHeight , e.target.scrollTop, e.target.clientHeight)
    // setBottom(!bottom);
  };

  const HandelFilters = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
    setStartDate(filterData.startDate)
    setEndDate(filterData.endDate)
    setLocationFilter(filterData.location)
    setStatusFilter(filterData.status)
    setServiceFilter(filterData.service)
    setTermFilter(filterData.term)
  }

  const handleClearFilters = () => {
    setStartDate()
    setEndDate()
    setLocationFilter([])
    setStatusFilter(null)
    setServiceFilter([])
    setTermFilter()
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const downloadData = async () => {
    let payload = []
    let Temppayload = {}
    if (endDate) {
      Temppayload.endDate = endDate
    }
    if (startDate) {
      Temppayload.startDate = startDate
    }
    if (locationFilter && locationFilter.length > 0) {
      Temppayload.locationId = locationFilter.map(value => value.id)
    } else {
      Temppayload.locationId = location.map(value => value.id)
    }
    if (serviceFilter && serviceFilter.length > 0) {
      if (param.path == 'student') {
        Temppayload.serviceId = serviceFilter.map(e => e.id)
      }
      else {
        Temppayload.search = serviceFilter.map(e => e.id)
      }
    }
    if (termFilter) {
      Temppayload.termNames = termFilter.id
    }
    if (statusFilter) {
      if (param.path == 'invoice') {
        Temppayload.xero_invoice_status = statusFilter.id
      } else if (param.path == 'family') {
        Temppayload.active = statusFilter.value
      } else if (param.path == 'payment') {
        Temppayload.paymentMethod = statusFilter.id
      } else {
        app = 'location'
      }
    }
    payload.push(Temppayload)

    if (startDate && !endDate) {
      toast.warning("Please select both start date and end date.")
    } else {
      setDownloadButtonLoading(true)
      if (app === 'crm-invoice' || app === 'payment-master') {
        let csvContent = '';
        if (app === 'crm-invoice') {
          csvContent = "data: text/csv; charset=utf-8, Invoice Number, Date, Due Date, Customer Name, Type, Status, Total Amount, Balance,Service Name, Location, Void Reason, Created At, Updated At, Source\r\n";
          let totalAmount = 0
          let totalPaid = 0
          let crmInvoiceData = []
          for (let i = 0; i < allData.length; i++) {
            let name = allData[i].family?.lastName + " " + allData[i].family?.firstName
            crmInvoiceData.push({
              invoiceNumber: allData[i].xero_invoice_no,
              date: allData[i].xero_invoice_date,
              dueDate: allData[i].xero_invoice_due_date,
              customerName: name,
              type: 'Invoice',
              status: allData[i].xero_invoice_status,
              amount: allData[i].amountPayable,
              balance: parseInt(allData[i].xero_invoice_balance),
              serviceName: allData[i]?.familyInvoiceItem[0]?.crmServiceMaster?.name,
              location: allData[i].location.name,
              voidReason: allData[i].voidReason,
              createdAt: allData[i].createdAt,
              updatedAt: allData[i].updatedAt,
              source: allData[i].source
            })
            totalPaid += parseInt(allData[i].xero_invoice_balance)
            totalAmount += allData[i].amountPayable
          }
          // console.log(res.data)
          crmInvoiceData.forEach((row) => {
            let csvRow = Object.values(row).join(",");
            csvContent += csvRow + "\r\n";
          });
          csvContent += `\r\n,,Total Invoice Amount, ${totalAmount},\r\n,,Total Payment, ${totalAmount - totalPaid},\r\n,,Balance, ${totalPaid}, \r\n`;
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `${param.path}.csv`);
          document.body.appendChild(link); // Required for FF
          link.click();
          setDownloadButtonLoading(false)
        } else {
          csvContent = "data: text/csv; charset=utf-8, ID, Invoice Number, Customer Name, Amount, Payment Method, Payment Date, Location, Description,Service Name\r\n";
          let totalAmount = 0
          let totalPaid = 0
          let paymentMasterData = []
          for (let i = 0; i < allData.length; i++) {
            let name = allData[i]?.invoice?.family?.lastName + " " + allData[i]?.invoice?.family?.firstName
            let numId = allData[i].id.toString()
            let locationName = allData[i]?.location?.name
            let prefix
            while (numId.length < 6) {
              numId = '0' + numId
            }
            if (
              locationName == 'Ringwood (Head Office)' ||
              locationName == 'Ringwood' ||
              locationName == 'Online' ||
              locationName == 'Narre Warren' ||
              locationName == 'Officer' ||
              locationName == 'Beaconsfield' ||
              locationName == 'Narre Warren' ||
              locationName == 'Endeavour Hills'
            ) {
              prefix = 'RWPAY-'
            } else {
              prefix = 'CNPAY-'
            }
            paymentMasterData.push({
              id: prefix + numId,
              invoiceNumber: allData[i]?.invoice?.xero_invoice_no,
              customerName: name,
              amount: allData[i]?.amount,
              paymentMethod: allData[i]?.paymentMethod,
              paymentDate: allData[i]?.date,
              location: allData[i]?.location?.name,
              description: allData[i]?.description,
              serviceName: allData[i]?.invoice?.familyInvoiceItem[0].crmServiceMaster?.name,
            })
            totalAmount += allData[i]?.invoice?.amountPayable
            totalPaid += allData[i]?.amount
          }
          paymentMasterData.forEach((row) => {
            let csvRow = Object.values(row).join(",");
            csvContent += csvRow + "\r\n";
          });
          csvContent += `\r\n,,Total Invoice Amount, ${totalAmount},\r\n,,Total Payment, ${totalPaid},\r\n,,Balance, ${totalAmount - totalPaid}, \r\n`;
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `${param.path}.csv`);
          document.body.appendChild(link); // Required for FF
          link.click();
          setDownloadButtonLoading(false)
        }
      } else {
        await app_api
          .post(`${downloadApp}/download`, payload)
          .then((res) => {
            const link = document.createElement('a')
            link.href = process.env.REACT_APP_STATIC_URL + res.data.path
            link.setAttribute('download', `${param.path}.csv`)
            document.body.appendChild(link)
            link.click()
            link.remove()
            setDownloadButtonLoading(false)
          }).catch((err) => {
            setError(err.toString());
            toast.error('Failed to download');
          })
      }
    }
  }

  const getFamilyData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `/family-master/select?&email=${email}`
    app_api
      .get(url)
      .then((res) => {
        setError(null)
        setFamily(res.data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getCrmData = () => {
    let url = `/crm-service`
    app_api
      .get(url)
      .then((res) => {
        setError(null)
        setCrmData(res.data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }


  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          columns={report_column({ column, types })}
          data={tabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
          progPage={page}
          totalCount={count}
        />
      </>
    );
  }, [column, tabData]);

  const getData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let locationValues = locationFilter && locationFilter.length > 0
      ? locationFilter.map(value => value.id)
      : null;
    let url = `${app}/report/all?page=${page}&size=${limit}&searchFilter=${searchFilter}&email=${email}`
    if (locationValues !== null && locationValues.length > 0) {
      url += `&location=${locationValues.join(',')}`;
    }
    if (statusFilter?.value) url = url + `&status=${statusFilter?.value}`
    if (startDate != null && endDate != null) url = url + `&start=${startDate}&end=${endDate}`
    if (familyFilter?.value) url = url + `&family=${familyFilter?.value}`
    let serviceFilterValues = serviceFilter && serviceFilter.length > 0
      && serviceFilter.map(value => value.id)
    if (serviceFilterValues.length > 0) {
      url += `&search=${serviceFilterValues.join(',')}`
    }
    if (termFilter) url = url + `&grade=${termFilter?.id}`

    app_api
      .get(url)
      .then(res => res.data)
      .then((res) => {
        setLoading(false)
        setError(null);
        if (res.count <= limit) setPage(0)
        setTabData(res.data)
        if (app === 'crm-invoice' || app === 'payment-master') {
          setAllData(res.allData)
        }
        if (isNaN(res.count / limit)) { setTotalPages(1) } else { setTotalPages(res.count / limit) }
        setCount(res.count)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getData()
  }, [limit, page, searchFilter, locationFilter, statusFilter, endDate, startDate, familyFilter, serviceFilter, termFilter])

  const getColumns = () => {
    app_api
      .get(`/user-setting/${user_id}/${columnName}`)
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setColumnsFilter(data)
        let dynamic_columns = data.map((key) => {
          return {
            Header: key.header == 'Centre' ? 'Location' : key.header,
            accessor: key.table
              ? key.table + '.' + key.column
              : key.column,
          }
        })
        let columns = [...dynamic_columns]
        columns = columns.filter(function (element) {
          return element !== undefined
        })
        setColumn(columns)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }


  const getLocationData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `location/all?&email=${email}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setLocation(res.data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getLocationData()
    getColumns()
    getCrmData()
  }, [])

  const cleanModalData = () => {
    setModal(initialModalState)
    setLocationFilter([])
    setStatusFilter(null)
    setStartDate()
    setEndDate()
  }


  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .patch(`/category/${id}`, { archive: 1 })
      .then((res) => {
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }


  const getCategoryData = () => {
    app_api
      .get('/category')
      .then((res) => {
        let data = res.data.filter((l) => l.archive == 0)
        let temp_categoryType = [...new Set(data.map((l) => {
          if (l.categoryType == null) { return 'No Category' }
          else { return l.categoryType }
        }))]
        setCategoryTypes(temp_categoryType)
        setLoading(false)
        setError(null)
        setCategory(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">{param.path.toUpperCase()} as per Services</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the {param.path}s as per services.
            </p>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
            <div className="relative mx-2">
              <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
                <div></div>
                <div className="flex justify-between gap-4">
                  <div
                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                  >
                    <CiFilter className="w-6 h-6" />
                  </div>
                  <div
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                    onClick={() => {
                      downloadData();
                    }}
                  >
                    {downloadButtonLoading ? (
                      <div className="flex items-center justify-center ">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                        </div>
                      </div>) : <MdOutlineFileDownload className="w-6 h-6" />}
                  </div>
                </div>
              </div>

              {/* Filter sidebar */}
              {isFilterSidebarOpen && (
                <div className='h-full'>
                <div className={`absolute bg-white border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-50`} >
                  <div className='flex justify-between p-2'>
                    <span className=" text-sm text-gray-700 ">Filters</span>
                    <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                      setFilterData({
                        startDate: null,
                        endDate: null,
                        status: '',
                        location: '',
                        category: '',
                        family: '',
                        service: '',
                        term: '',
                      })
                      handleClearFilters()
                    }}> Reset All </span>
                  </div>
                  <hr className='' />
                  <div className="w-full flex flex-col p-2">
                    <div className='flex justify-between mb-2'>
                      <span className=" text-sm text-gray-700 ">Date Range</span>
                      <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                        setFilterData({
                          ...filterData,
                          startDate: null,
                          endDate: null,
                        })
                      }}> Reset </span>
                    </div>
                    <div className="w-full flex gap-2 justify-between">
                      <div className="flex customDatePickerWidth items-center">
                        <DatePicker
                          selected={null}
                          onChange={(dates) => {
                            const [start, end] = dates
                            // setStartDate(start)
                            // setEndDate(end)
                            setFilterData({ ...filterData, startDate: start, endDate: end })
                          }}
                          showIcon
                          isClearable
                          startDate={filterData.startDate}
                          endDate={filterData.endDate}
                          dateFormat="MMM dd, yyyy"
                          selectsRange
                          className="text-center block py-2 px-2 border w-full appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholderText="Select Date Range"
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className='' />
                  <div className="w-full p-2">
                    <div className='flex justify-between mb-2'>
                      <span className=" text-sm text-gray-700 ">Location</span>
                      <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                        setFilterData({
                          ...filterData,
                          location: ''
                        })
                      }}> Reset </span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="location"
                      id="location"
                      isSearchable
                      loadingMessage="Getting Location..."
                      placeholder="Location"
                      isClearable={true}
                      isMulti
                      options={location?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      value={filterData.location}
                      onChange={(option) => {
                        setFilterData({
                          ...filterData,
                          location: option,
                        })
                        // setLocationFilter(option)
                      }}
                    />
                  </div>
                  <hr className='' />
                  <div className="w-full p-2">
                    <div className='flex justify-between mb-2'>
                      <span className=" text-sm text-gray-700 ">Service</span>
                      <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                        setFilterData({
                          ...filterData,
                          service: ''
                        })
                      }}> Reset </span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="service"
                      id="service"
                      isSearchable
                      loadingMessage="Getting service..."
                      placeholder="Service"
                      isClearable={true}
                      isMulti
                      options={crmData?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      maxMenuHeight={110}
                      onChange={(option) => {
                        // setServiceFilter(option)
                        setFilterData({
                          ...filterData,
                          service: option,
                        })
                      }}
                      value={filterData.service}
                    />
                  </div>
                  <hr className='' />
                  {param.path != 'student' && (<>
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">{param.path == 'invoice' ? "Status" : param.path == 'family' ? "Status" : "Payment Method"}</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            status: ''
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="invoiceStatus"
                        id="invoiceStatus"
                        placeholder={param.path == 'invoice' ? "Status" : param.path == 'family' ? "Status" : "Payment Method"}
                        isClearable={true}
                        value={
                          filterData.status
                            ? param.path == 'invoice' ? invoiceStatusType.map((l) => ({
                              value: invoiceStatusType.find(
                                (e) => e.id == filterData.status.id
                              )?.id,
                              label: invoiceStatusType.find(
                                (e) => e.id == filterData.status.id
                              )?.name,
                            })) : param.path == 'family' ? familyStatus.map((l) => ({
                              value: familyStatus.find(
                                (e) => e.id == filterData.status.id
                              )?.id,
                              label: familyStatus.find(
                                (e) => e.id == filterData.status.id
                              )?.name,
                            })) : paymentType.map((l) => ({
                              value: paymentType.find(
                                (e) => e.id == filterData.status.id
                              )?.id,
                              label: paymentType.find(
                                (e) => e.id == filterData.status.id
                              )?.name,
                            }))
                            : null
                        }
                        options={
                          param.path == 'invoice' ? invoiceStatusType?.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          })) : param.path == 'family' ? familyStatus?.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          })) : paymentType?.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          }))}
                        onChange={(option) => {
                          // setStatusFilter(option)
                          setFilterData({
                            ...filterData,
                            status: option,
                          })
                        }}
                      />
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Term</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            term: ''
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="term"
                        id="term"
                        isSearchable
                        loadingMessage="Getting Term..."
                        placeholder="Term"
                        isClearable={true}
                        options={Terms?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          // setTermFilter(option)
                          setFilterData({
                            ...filterData,
                            term: option,
                          })
                        }}
                        value={filterData.term}
                      />
                    </div>
                    <hr className='' />
                  </>)}
                  <hr className='' />
                  <div className="flex justify-between gap-2 p-2">
                    <div className="">
                      <Button
                        type="button"
                        className={'bg-red-500 hover:bg-red-700'}
                        onClick={() => {
                          setFilterData({
                            startDate: null,
                            endDate: null,
                            status: '',
                            location: '',
                            category: '',
                            family: '',
                            service: '',
                            term: '',
                          })
                          handleClearFilters()
                        }}
                      >
                        Reset All
                      </Button>
                    </div>
                    <div className="">
                      <Button
                        type="button"
                        onClick={() => { HandelFilters() }}
                      >
                        Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                </div>
              )}
            </div>

            {renderTable()}
            <nav
              className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value)
                      setPage(0)
                    }}
                    value={limit}
                  >
                    <option value={count}>{count}</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>{' '}
                  of <span className="font-medium">{count}</span> results
                </p>
              </div>
              <div className="flex items-center md:justify-end">
                <span
                  onClick={goPrev}
                  className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Previous
                </span>
                <span className="text-sm">
                  Page{' '}
                  <input
                    className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                    type="number"
                    value={page + 1}
                    onChange={(e) => setPage(e.target.value - 1)}
                    max={totalPages}
                    min="1"
                  />
                  <span className='ml-2'></span>/ {Math.ceil(totalPages)}
                </span>
                <span
                  onClick={goNext}
                  className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Next
                </span>
              </div>
            </nav>
          </>}


      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false} />
    </Sidebar>
  )
}

export default ReportPage
