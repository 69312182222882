import React from 'react'
import { toast } from "react-toastify"
import app_api from "../../config/api"
import { markAsPaidSchema } from "../../schema"
import Modal from '../../components/modals'
import { Formik } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CurrencyInput from 'react-currency-input-field'
import Select from 'react-select'
import Button from '../../components/button'

const MarkAsPaidModal = (props) => {
  const { type, paidState, data } = props.markAsPaidModal
  const { markAsPaidPayable, discription, getContactData, onEditInvoiceOpen, onFamilyEditOpen, cleanMarkAsPaidModalData, setError, setMarkAsPaidModal, paymentType, setDiscription, loading } = props
  return (
    <Formik
      initialValues={data}
      validationSchema={markAsPaidSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        let consition1 = values.amount
          ? parseInt(values.amount) <= markAsPaidPayable
          : false
        if (consition1) {
          app_api
            .patch(`/crm-invoice/mark-as-paid-invoice/${data.id}`, {
              ...values,
              description: discription,
            })
            .then((res) => {
              toast.success('Payment is registered')
              setSubmitting(false)
              getContactData()
              onEditInvoiceOpen(res.data.id)
              onFamilyEditOpen(res.data.customerId)
              cleanMarkAsPaidModalData()
              resetForm(true)
            })
            .catch((err) => {
              setSubmitting(false)
              setError(err?.response?.data?.message || 'error getting data')
              resetForm(false)
              toast.error('Something went wrong')
            })
        } else {
          toast.error('Amount is greater than Invoice Amount')
          setSubmitting(false)
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        resetForm,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }) => (
        <Modal
          title="Register a Payment"
          open={paidState}
          setOpen={() => {
            resetForm(true)
            setMarkAsPaidModal((prev) => ({ ...prev, paidState: false }))
          }}
        >
          <form onSubmit={handleSubmit} noValidate>
            <div className="text-left mt-2">
              <div className="flex justify-center mb-2">
                <p>{data.xero_invoice_no}</p>
              </div>
              <div className="flex">
                <label className="block text-sm font-medium text-gray-700">
                  Date
                </label>
                <span className="text-red-700 ml-1">*</span>
              </div>
              <div className="customDatePickerWidth">
                <DatePicker
                  className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                  name="date"
                  id="date"
                  dateFormat="dd-MM-yyyy"
                  selected={values.date ? values.date : new Date()}
                  onChange={(date) => {
                    setValues((prev) => ({
                      ...prev,
                      date: date,
                    }))
                  }}
                  placeholderText="Payment Date"
                />
              </div>
              {touched.date && (
                <p className="text-red-700 error_msg">{errors.date}</p>
              )}
              <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-1 flex flex-wrap">
                <div className="col-span-2 mt-4">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Amount
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <CurrencyInput
                    id="amount"
                    name="amount"
                    decimalsLimit={2}
                    value={values.amount}
                    placeholder="Enter amount"
                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    prefix="$"
                    onValueChange={(value) => {
                      setValues({
                        ...values,
                        amount: value,
                      })
                    }}
                  />
                  {touched.amount && (
                    <p className="text-red-700 error_msg">{errors.amount}</p>
                  )}
                </div>
                <div className="col-span-2 mt-4">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700 mt-1">
                      Invoice Amount
                    </label>
                  </div>
                  <CurrencyInput
                    id="amountPayable"
                    name="amountPayable"
                    disabled={true}
                    decimalsLimit={2}
                    value={markAsPaidPayable}
                    placeholder="Enter amount"
                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    prefix="$"
                  />
                </div>
              </div>
              <div className="flex mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Method
                </label>
                <span className="text-red-700 ml-1">*</span>
              </div>
              <Select
                className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                name="paymentMethod"
                id="paymentMethod"
                isSearchable
                loadingMessage="Getting Payment Method..."
                placeholder="Select a payment method..."
                value={
                  values.paymentMethod
                    ? paymentType?.map((l) => ({
                        value: paymentType.find(
                          (e) => e.id == values.paymentMethod
                        )?.id,
                        label: paymentType.find(
                          (e) => e.id == values.paymentMethod
                        )?.name,
                      }))
                    : null
                }
                options={paymentType?.map((l) => ({
                  ...l,
                  label: l.name,
                  value: l.id,
                }))}
                onChange={(option) => {
                  setValues({
                    ...values,
                    paymentMethod: option.id,
                  })
                }}
                onBlur={handleBlur}
                menuPlacement="auto"
                maxMenuHeight={110}
              />
              {touched.paymentMethod && (
                <p className="text-red-700 error_msg">{errors.paymentMethod}</p>
              )}
              <div className="flex mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
              </div>
              <textarea
                name="description"
                id="description"
                rows={4}
                // cols={51}
                className="border border-gray-300 rounded-md px-1 py-2 w-full"
                value={discription}
                onChange={(event) => {
                  setDiscription(event.target.value)
                }}
              />
              {touched.description && (
                <p className="text-red-700 error_msg">{errors.description}</p>
              )}
              <div className="mt-4 sm:mt-6">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={loading}
                  // onClick={() => {
                  //   setLoading(true)
                  //   markAsPaidFunction(values, data)
                  // }}
                >
                  Register a payment
                </Button>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </Formik>
  )
}

export default MarkAsPaidModal
