import { Button } from './tables/paginationButtons'

const AddSection = ({ title, buttonText, description, onClick }) => {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-3xl font-semibold text-gray-900">{title}</h1>
        <p className="my-2 text-sm text-gray-700">{description}</p>
      </div>
      <div className="sm:mt-0 sm:ml-16 sm:flex-none">
        <Button onClick={onClick} className="flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mt-0 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
          
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default AddSection
