import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon,CheckCircleIcon,XCircleIcon } from '@heroicons/react/24/outline'
import Button from './button'

export default function BigSlideover(props) {
  const { title, description, open, setOpen, children, handleClick ,data,resendInvoice} = props

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto big_slideover">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    {data?.xero_invoice_balance == 0 && data?.xero_invoice_status == 'PAID' ? <>
                        <div className="bg-green-800 px-4 py-6 sm:px-6 ">
                          <div className="flex justify-between">
                            <div className='flex items-center'>
                              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                {title}
                              </Dialog.Title>
                              <div className='text-center text-white ml-3'>
                                <CheckCircleIcon className='w-5 text-green-200 ' />
                              </div>
                              <div className='text-center text-white ml-1'>
                                <span className="">PAID</span>
                              </div>
                            </div>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-green-700 text-green-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">{description}</p>
                          </div>
                        </div>
                      </> : data?.xero_invoice_balance == 0 && data?.xero_invoice_status == 'VOIDED' ? <>
                        <div className="bg-red-700 px-4 py-6 sm:px-6 ">
                          <div className="flex justify-between">
                            <div className='flex items-center'>
                              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                {title}
                              </Dialog.Title>
                              <div className='text-center text-white ml-3'>
                                <XCircleIcon className='w-5 text-red-200 ' />
                              </div>
                              <div className='text-center text-white ml-1'>
                                <span className="">VOIDED</span>
                              </div>
                            </div>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-red-700 text-red-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">{description}</p>
                          </div>
                        </div>
                      </> : data?.status == "Enrolled" ?
                        <div className="bg-green-800 px-4 py-6 sm:px-6 ">
                          <div className="flex justify-between">
                            <div className='flex items-center'>
                              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                              Lead {data.status}
                              </Dialog.Title>
                            </div>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-green-700 text-green-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">{description}</p>
                          </div>
                        </div> :
                        data?.status == "Retired" ?
                          <div className="bg-red-700 px-4 py-6 sm:px-6 ">
                            <div className="flex justify-between">
                              <div className='flex items-center'>
                                <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                Lead {data.status} 
                                </Dialog.Title>
                              </div>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-red-700 text-green-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                  onClick={() => setOpen(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                            <div className="mt-1">
                              <p className="text-sm text-indigo-300">{description}</p>
                            </div>
                          </div> :
                          <>
                            <div className=" bg-indigo-900 px-4 py-6 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                  {title}
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center space-x-4">
                                  {data?.xero_invoice_status == 'AWAITING PAYMENT' && (
                                    <Button
                                      type="button"
                                      className="bg-green-600 hover:bg-green-700 duration-500 cursor-pointer"
                                      onClick={() => resendInvoice()}
                                    >Resend invoice
                                    </Button>
                                  )}
                                  <button
                                    type="button"
                                    className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                    onClick={() => setOpen(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                </div>
                              </div>
                              <div className="mt-1">
                                <p className="text-sm text-indigo-300">{description}</p>
                              </div>
                            </div>
                          </>
                    }
                    {/* <div className="bg-green-900 px-4 py-6 sm:px-6 bg-indigo-900 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">{description}</p>
                      </div>
                    </div> */}
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
