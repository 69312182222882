import { Cog8ToothIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/button'

export default function TermMasterHeader(props) {
  const { cleanModalData, setModal } = props
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-3xl font-semibold text-gray-900">Term Dates</h1>
        <p className="my-2 text-sm text-gray-700">
          A list of all the Term Dates.
        </p>
        <nav className="flex mt-4 " aria-label="Breadcrumb">
          <ol
            role="list"
            className="flex space-x-4 rounded-md bg-white px-6 shadow"
          >
            <li className="flex">
              <div className="flex items-center">
                <Link
                  to="/setting"
                  className="text-gray-400 hover:text-gray-500 flex items-center gap-2"
                >
                  <Cog8ToothIcon
                    className="h-5 w-5 flex-shrink-0 "
                    aria-hidden="true"
                  />
                  <span className="">Setting</span>
                </Link>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg
                  className="h-full w-6 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <Link
                  to={'/setting/term-master'}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  Term Master
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="flex justify-between items-center sm:mt-0 sm:ml-16 sm:flex-none gap-4">
        <Button
          onClick={() => {
            cleanModalData()
            setModal((prev) => ({ ...prev, type: 'add', state: true }))
          }}
          className="flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 mt-0 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          Add
        </Button>
      </div>
    </div>
  )
}
