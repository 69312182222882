import React from 'react'
import { CiFilter } from 'react-icons/ci'
import Select from 'react-select'
import Button from '../../components/button'

export default function TermFilterSidebar(props) {
  const {
    filterData,
    stateData,
    selectedState,
    isFilterSidebarOpen,
    setSelectedState,
    setFilterData,
    setIsFilterSidebarOpen,
    handleClearFilters,
    handleFilters,
  } = props
  return (
    <>
      <div className="relative mx-2">
        <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
          <div></div>
          <div className="flex justify-between gap-4 ">
            <div
              onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
              className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
            >
              {filterData.loading ? (
                <div className="flex items-center justify-center">
                  <div
                    className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                    role="status"
                  ></div>
                </div>
              ) : (
                <CiFilter className="w-6 h-6" />
              )}
            </div>
          </div>
        </div>
        {isFilterSidebarOpen && (
          <div className="h-full">
            <div
              className={`absolute bg-white  border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-50`}
            >
              <div className="flex justify-between p-2">
                <span className=" text-sm text-gray-700 ">Filters</span>
                <span
                  className="text-sm text-indigo-700 cursor-pointer"
                  onClick={() => {
                    setFilterData({
                      state: [],
                      loading: false,
                    })
                    handleClearFilters()
                  }}
                >
                  Reset All
                </span>
              </div>
              <hr className="" />
              <div className="w-full p-2">
                <div className="flex justify-between mb-2">
                  <span className=" text-sm text-gray-700 ">State</span>
                  <span
                    className="text-sm text-indigo-700 cursor-pointer"
                    onClick={() => {
                      setFilterData({
                        ...filterData,
                        state: '',
                      })
                    }}
                  >
                    Reset
                  </span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="state"
                  id="state"
                  isSearchable
                  loadingMessage="Getting State..."
                  placeholder="State"
                  isClearable={true}
                  options={stateData?.map((l) => ({
                    label: l.value,
                    value: l.value,
                  }))}
                  value={selectedState}
                  onChange={(option) => {
                    setSelectedState([option])
                  }}
                />
              </div>
              <hr className="" />
              <div className="flex justify-between gap-2 p-2">
                <div className="">
                  <Button
                    type="button"
                    className={'bg-red-500 hover:bg-red-700'}
                    onClick={() => {
                      setFilterData({
                        location: '',
                        loading: false,
                      })
                      handleClearFilters()
                    }}
                  >
                    Reset All
                  </Button>
                </div>
                <div className="">
                  <Button
                    type="button"
                    onClick={() => {
                      handleFilters()
                    }}
                  >
                    Apply Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
