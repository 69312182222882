import { useState, useEffect } from 'react'
import app_api from '../../config/api'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Sidebar } from '../../components/navigation/sidebar'

const ParentSchedule = () => {
    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState([])

    const getStudentSchedule = () => {
        let email = JSON.parse(localStorage.getItem('moldKey')).email
        let url = `family-master/schedule/${email}`
        app_api
            .get(url)
            .then((res) => {
                setLoading(false);
                const convertedEvents = [];
                if (Array.isArray(res.data.studentMaster)) {
                    res.data.studentMaster.forEach((s) => {
                        if (Array.isArray(s?.attendance)) {
                            s?.attendance?.forEach((l) => {
                                convertedEvents.push({
                                    start: new Date(l?.session?.start_time),
                                    end: new Date(l?.session?.end_time),
                                    title: `${s?.studentsFirstName} - ${l?.session?.class?.subject?.subject}`,
                                    subject: l?.session?.class?.subject?.subject
                                });
                            });
                        }
                    });
                }
                setEvents(convertedEvents);
            })
            .catch((err) => {
                setLoading(false)
            })

    }

    useEffect(() => {
        getStudentSchedule()
    }, [])

    const localizer = momentLocalizer(moment)



    return (
        <Sidebar>
                <div className="max-w-[1680px] mx-auto p-4 max-h-screen">
                    <div className="px-2 ">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-lg font-semibold text-gray-900 dashboardTitle">
                                    Welcome {JSON.parse(localStorage.getItem('moldKey'))?.name}
                                </h1>
                                <p className="my-2 text-sm text-gray-700 ml-2">
                                    Here's your list of classes
                                </p>
                            </div>
                        </div>

                        {loading ? (
                            <div className="flex items-center justify-center h-screen">
                                <div className="spinner-border animate-spin w-8 h-8 border-4 rounded-full" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <div className="h-screen flex mb-4">
                                <div className="w-full ">
                                    <Calendar
                                        localizer={localizer}
                                        events={events}
                                        startAccessor="start"
                                        endAccessor="end"
                                        style={{ height: '80%' }}
                                        className="bg-white rounded shadow-lg p-4"
                                    />
                                </div>
                            </div>
                        )}

                    </div>
                </div>

            <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
        </Sidebar>
    )
}

export default ParentSchedule
