import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  PencilIcon,
  XCircleIcon,
  TrashIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline'
import Editor from 'jodit-react'
import AddSection from '../components/add_section'
import Button from '../components/button'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import Input from '../components/input'
import Modal from '../components/modals'
import { Sidebar } from '../components/navigation/sidebar'
import app_api from '../config/api'
import '../theme/style.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { editorConfig } from '../config/editor_config'
import { Draggable } from 'react-drag-reorder'
import 'react-accessible-accordion/dist/fancy-example.css'
import moment from 'moment'
import Select from 'react-select'

const SingleExamPage = () => {
  const [open, setOpen] = useState(false)
  const [modal, setModalType] = useState('')
  const [currentTab, setCurrentTab] = useState(1)
  const [questionField, setQuestionFields] = useState({})
  const [answerFields, setAnswerFields] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [singleExam, setSingleExam] = useState([])
  const [catLoading, setCatLoading] = useState(false)
  const [catError, setCatError] = useState(null)
  const [category, setCategory] = useState([])

  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const params = useParams()
  const editorRef = useRef()

  const interval = 2500

  const getActiveColor = (active) => {
    return active ? 'bg-green-600' : ''
  }

  const getActiveTextColor = (active) => {
    return active ? 'text-white' : 'text-gray-700'
  }

  const handleFormChange = (index, event) => {
    let data = [...answerFields]
    data.forEach((item, idx) => {
      data[index]['answer'] = event
    })
    setAnswerFields(data)
  }

  const handleFormAnswerChange = (index, event) => {
    let data = [...answerFields]
    if (event.target.name === 'is_correct') {
      data.forEach((item, idx) => {
        data[idx][event.target.name] = false
      })
      data[index][event.target.name] = true
    } else {
      data[index][event.target.name] = event.target.value
    } 
    setAnswerFields(data)
  }

  const addFields = () => {
    let newfield = { name: '', is_correct: null }
    setAnswerFields([...answerFields, newfield])
  }

  const removeFields = (index) => {
    let data = [...answerFields]
    data.splice(index, 1)
    setAnswerFields(data)
  }

  const onContentStateChange = (content, id) => {
    setQuestionFields((prev) => ({
      ...prev,
      question: content,
      id: id,
    }))
  }

  const onJustificationChange = (content, id) => {
    setQuestionFields((prev) => ({
      ...prev,
      justification: content,
      id: id,
    }))
  }

  const getCategoryData = () => {
    app_api
      .get('/category')
      .then((res) => {
        setCatLoading(false)
        setCatError(null)
        setCategory(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setCatError(err?.response?.data?.message || 'error getting categories')
      })
  }

  const renderType = (questionData, answerData) => {
    return {
      1: (
        <div className="mt-6 text-left z-auto">
          <Editor
            config={editorConfig}
            className="jodit-status-bar__item"
            ref={editorRef}
            value={questionField.question}
            onChange={(newContent) =>
              onContentStateChange(newContent, questionData.id)
            }
          />
          <div className="mt-4"></div>
          <Input
            name="marks"
            label="Marks"
            placeholder="Enter marks..."
            type="number"
            autoComplete="off"
            className="mb-4"
            value={questionData?.marks}
            onChange={(event) =>
              setQuestionFields((prev) => ({
                ...prev,
                marks: event.target.value,
              }))
            }
          />

          <div>
            <label htmlFor="category-choice">Category</label>
            <Select
              id="category-choice"
              name="category"
              className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={
                questionData?.category
                ? category.map((l) => ({
                      value: category.find(
                        (e) => e.category == questionData.category
                      )?.id,
                      label: category.find(
                        (e) => e.category == questionData.category
                      )?.category,
                    }))
                  : null
              }
              placeholder="Select a Category"
              autoComplete="off"
              onChange={(option) => {
                setQuestionFields((prev) => ({
                  ...prev,
                  category: option.label,
                }))
              }}
              options={category?.map((l) => ({
                ...l,
                label: l.category,
                value: l.id,
              }))}
            ></Select>

            <div className="mt-4"></div>

            <label htmlFor="justification">Justification</label>
            {/* <textarea
              rows={4}
              name="justification"
              id="justification"
              placeholder="Enter justification for the question"
              className="block w-full p-2 mb-4 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border"
              value={questionData?.justification}
              onChange={(event) =>
                setQuestionFields((prev) => ({
                  ...prev,
                  justification: event.target.value,
                }))
              }
            /> */}
            <Editor
            config={editorConfig}
            className="jodit-status-bar__item"
            ref={editorRef}
            value={questionField.justification}
            onChange={(newContent) =>
              onJustificationChange(newContent, questionData.id)
            }
          />
          </div>

          <div className="mt-6">
            {answerData.map((answer, index) => {
              return (
                <div
                  className="flex items-center justify-between mb-2 text-center"
                  key={index}
                >
                  {/* <Input
                    name="answer"
                    className="w-3/4"
                    placeholder="Enter Options..."
                    value={answer.answer}
                    onChange={(event) => handleFormChange(index, event)}
                  />x */}
                  <Editor
                    config={editorConfig}
                    className="jodit-status-bar__item"
                    name="answer"
                    ref={editorRef}
                    value={answer.answer}
                    // onChange={(newContent) =>
                    //   onJustificationChange(newContent, questionData.id)
                    // }
                    onChange={(content) => handleFormChange(index, content)}
                  />
                  <div key={index} className="flex items-center">
                    <input
                      id={answer.answer}
                      name="is_correct"
                      type="radio"
                      onChange={(event) => handleFormAnswerChange(index, event)}
                      defaultChecked={answer.is_correct}
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={answer.answer}
                      className="block ml-1 text-sm font-medium text-gray-700"
                    >
                      Correct
                    </label>
                  </div>
                  <XCircleIcon
                    onClick={() => removeFields(index)}
                    className="w-8 h-full ml-2 -mr-1 text-red-600 hover:cursor-pointer"
                    aria-hidden="true"
                  />
                </div>
              )
            })}

            <div className="flex mt-4">
              <Button
                className="mr-2"
                disabled={answerFields.length < 2}
                onClick={() => {
                  modal === 'add'
                    ? addQuestion()
                    : onUpdateQuestion(questionData.id)
                }}
              >
                {modal === 'add' ? 'Submit' : 'Submit'}
              </Button>
              <Button className="ml-2" onClick={() => addFields()}>
                Add Option
              </Button>
            </div>
            {/* <div>
                <Button
                  onClick={() => {
                    modal === 'add'
                      ? addQuestion()
                      : onUpdateQuestion(questionData.id)
                  }}
                  >
                  {modal === 'add' ? 'Add Question' : 'Update Question'}
                </Button>
            </div> */}
          </div>
        </div>
      ),
    }
  }

  const addQuestion = () => {
    app_api
      .post('/question', {
        question: questionField.question,
        marks: Number(questionField.marks),
        exam_id: Number(params.id),
        justification: questionField?.justification,
        type: 'single',
        category: questionField?.category ? questionField?.category : 'others' ,
        is_active: true,
      })
      .then((res) => {
        let updateSingleExam = { ...singleExam }
        updateSingleExam.questions.push({
          ...res.data,
          answers: [],
        })

        const question_id = res.data.id
        addAnswers(question_id)
        setQuestionFields((prev) => ({
          ...prev,

          id: res.data.id,
          question: res.data.question,
        }))
        toast.success('Question Added Successfully..')
      })
      .catch((err) => {
        let message = err.response.data.message[0];
        toast.error(`${message}`)
      })
  }

  const addAnswers = (data) => {
    app_api
      .post(`/answer/bulk`, {
        answers: answerFields,
        question_id: Number(data),
      })
      .then((res) => {
        setOpen(false)
        cleanModalData()
        getExamData()
      })
      .catch((err) => {
        toast.error('Unable to add answer. Please try again')
      })
  }

  const renderEditModal = (type) => {
    return (
      <Modal
        className="max-w-lg-2"
        title={modal.toUpperCase()}
        open={open}
        setOpen={() => {
          clearData()
          cleanModalData()
          setOpen(false)
        }}
      >
        <div className="mt-4">
          <div>{renderType(questionField, answerFields)[type]}</div>
        </div>
      </Modal>
    )
  }

  const cleanModalData = () => {
    setQuestionFields({})
    setAnswerFields([])
  }

  const getChangedPos = (currentPos, newPos) => {
  }

  const onUpdateQuestion = (id) => {
    app_api
      .patch(`/question/${id}`, {
        question: questionField.question,
        category: questionField.category,
        justification: questionField?.justification,
        marks: Number(questionField?.marks),
      })
      .then((res) => {
        setOpen(false)
        toast.success('Updated Successfully')
        let updateSingleExam = { ...singleExam }
        updateSingleExam.questions[questionField.index].question =
          questionField.question
        updateSingleExam.questions[questionField.index].category.category =
          questionField.category
        updateSingleExam.questions[questionField.index].justification =
          questionField?.justification
        updateSingleExam.questions[questionField.index].marks =
          questionField?.marks
        addUpdateQuestion(questionField.id, answerFields)
        setSingleExam(updateSingleExam)
        cleanModalData()
        // window.location.reload(false);
        // getExamData()
      })
      .catch((err) => {
        setOpen(false)
        toast.error('Something went wrong')
      })
  }

  const addUpdateQuestion = (id, answer) => {
    app_api
      .post(`/answer/bulk`, {
        answers: answer,
        question_id: Number(id),
      })
      .then((res) => {
        setOpen(false)
        cleanModalData()
        getExamData()
      })
      .catch((err) => {
        toast.error('Unable to add answer. Please try again')
      })
  }

  const getExamData = () => {
    app_api
      .get(`/exam/${params.id}`)
      .then((res) => {
        setSingleExam(res.data)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const clearData = () => {
    setQuestionFields({})
    setAnswerFields([])
  }

  const deleteQuestion = () => {
    const id = questionField.id
    app_api
      .delete(`/question/${id}`)
      .then((res) => {
        getExamData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  function convert_from_sqrt_to_styled(text) {
    const pattern = /\$sqrt\((\d+)\)/g;

    const replacedText = text?.replace(pattern, (match, number) => {
      return `<span style="white-space: nowrap; font-size:larger">
              &radic;<span style="text-decoration:overline;">&nbsp;${number}&nbsp;</span>
            </span>`;
    });
    return replacedText;
  }

  useEffect(() => {
    getExamData()
    getCategoryData()
  }, [])

  return (
    <Sidebar>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={deleteQuestion}
        clearDataOnClose={clearData}
      />
      <div className=" flex flex-column justify-between">
        <div className="sm:flex sm:items-center w-3/4 top-0 h-[100px]">
          <div className="sm:flex-auto fixed-h1-margin">
            <h1 className="text-3xl font-semibold text-gray-900">Question</h1>
            <p className="my-2 text-sm text-gray-700">
              Drag and Drop to rearrange.
            </p>
          </div>
          <div className="sm:mt-0 mx-2 sm:flex-none ">
            <Button
              onClick={() => {
                setModalType('add')
                setOpen(true)
              }}
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        <div className="w-9/12 mb-10">
          {renderEditModal(currentTab)}
          {singleExam?.questions?.length > 0 &&
            singleExam?.questions?.map((singleExamData, index) => {
              return (
                <>
                  <div className="bg-white border shadow-md mr-3 ml-3 mt-5 mb-3 rounded-lg pb-3 fontStandard">
                    <div className="mx-3 my-3 relative items-center block border-b-1 rounded-lg ring-0 ring-indigo-500 focus:outline-none">
                      <div className="sm:flex sm:justify-between">
                        <div className="flex justify-start">
                          <span className="mr-2">Q.{index + 1} </span>
                          <div
                            className="inline-first-p"
                            dangerouslySetInnerHTML={{
                              __html:  convert_from_sqrt_to_styled(singleExamData.question),
                            }}
                          ></div>{' '}
                        </div>

                        <span className="flex">
                          <PencilIcon
                            onClick={() => {
                              setModalType('edit')
                              setOpen(true)
                              setQuestionFields({
                                question: singleExamData.question,
                                id: singleExamData.id,
                                index: index,
                                marks: singleExamData?.marks,
                                category: singleExamData?.category?.category,
                                justification: singleExamData?.justification,
                              })

                              setAnswerFields(singleExamData.answers)
                            }}
                            className="w-4 h-4 mx-2 text-gray-600 hover:cursor-pointer"
                            aria-hidden="true"
                          />

                          <TrashIcon
                            onClick={() => {
                              setQuestionFields({
                                question: singleExamData.question,
                                id: singleExamData.id,
                                index: index,
                                marks: singleExamData?.marks,
                              })
                              setConfirmationDialog(true)
                            }}
                            className="w-4 h-4 mx-2 text-red-600 hover:cursor-pointer"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                    </div>
                    <fieldset>
                      <div className="mx-10 mt-2 mx-12 border-t border-b border-gray-200 divide-y divide-gray-200">
                        {singleExamData?.answers?.map((answer, idx) => (
                          <div
                            key={idx}
                            className={`relative flex items-start py-2 px-2 rounded-sm ${getActiveColor(
                              answer.is_correct
                            )}`}
                          >
                            <div className="flex-1 min-w-0 text-sm">
                              <label
                                htmlFor={`${answer}`}
                                className={`select-none font-medium ${getActiveTextColor(
                                  answer.is_correct
                                )} flex`}
                              >
                                {`${idx + 1}. `}
                                <span
                                className='ml-2'
                                dangerouslySetInnerHTML={{
                                  __html: convert_from_sqrt_to_styled(answer.answer),
                                }}
                                ></span>
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                </>
              )
            })}
        </div>
        <div className="w-[300px] right-0 top-0 h-[750px] fixed rounded border-l  p-4 overscroll-y-none  bg-white rounded-3xl ">
          <h1 className="text-xl text-center font-bold">Exam Details</h1>
          <div>
            <div className="flex flex-row justify-between mt-4">
              <h1 className="font-bold text-gray-900 text-capitalize">
                <span className="text-sm mt-4 mb-2 font-semibold">Name:</span>
                <br></br>
                <span className="text-lg rounded-md text-blue-900 w-max py-1">
                  {singleExam?.name}
                </span>
              </h1>
            </div>

            <h4 className="text-md mt-4 mb-2 font-semibold">Questions:</h4>
            <p className="text-white rounded-md bg-blue-900 px-3 text-md py-1 mb-2 text-center w-max">
              {singleExam?.questions?.length}
            </p>
            <h4 className="text-md mt-3 mb-2 font-semibold">Duration:</h4>
            <p className="text-white rounded-md bg-blue-900 px-3 text-md py-1 mb-2 text-center w-max">
              {singleExam?.duration + ' Min'}
            </p>
          </div>
          <label className="mt-3 font-semibold">Status:</label>
          <br></br>
          {singleExam?.status == 'active' ? (
            <div className="flex flex-row">
              <div className="flex flex-row items-center">
                <div className="w-4 h-4 bg-green-600 rounded-full mr-2"></div>
              </div>
              Active
            </div>
          ) : (
            <div className="flex flex-row">
              <div className="flex flex-row items-center">
                <div className="w-4 h-4 bg-red-700 rounded-full mr-2"></div>
              </div>
              Draft
            </div>
          )}
          <div>
            <h4 className="text-md mt-3 mb-2 font-semibold">Last Edited</h4>
            <p className="rounded-md w-max bg-yellow-200 px-2 text-yellow-800 font-semibold text-sm">
              {moment(singleExam?.updatedAt).format('DD MMM YYYY hh:mm A')}
            </p>
          </div>

          {/* <h4 className="text-md mt-4 mb-2 font-semibold">Actions</h4>
          <div className="flex flex-wrap justify-between">
            <button
              type="button"
              className="inline-flex items-center text-gray-900 bg-blue-200 border border-gray-300 focus:outline-none hover:bg-gray-200 duration-500 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1 mr-2 mb-2 "
            >
              <DocumentDuplicateIcon className="w-5 mr-2" />
              Duplicate
            </button>
            <button
              type="button"
              className="inline-flex items-center text-white bg-red-700 border border-gray-300 focus:outline-none hover:bg-red-500 duration-500 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1 mr-2 mb-2"
            >
              <TrashIcon className="w-4 mr-2" />
              Delete
            </button>
          </div>
          {/* <button
            type="button"
            className="mt-6 w-100 pt-auto text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 mr-2 mb-2"
          >
            Save Changes
          </button> */}
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default SingleExamPage
