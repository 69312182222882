import { useState, useEffect } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import {
 batch_student_columns
} from '../components/tables/tableheader'
import Label from '../components/label'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    total_score: '',
    graceMarks: 0
  },
}

const BatchStudent = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [showcontact, setShowContact] = useState(false)
  const [error, setError] = useState(null)
  const [enrolments, setEnrolments] = useState([])
  const [batch, setBatch] = useState([])
  const [exam, setExam] = useState('')

  const params = useParams()

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: {
        total_score: (enrolments[index].total_score + enrolments[index].grace_marks),
        graceMarks: enrolments[index].grace_marks || 0
      },
    }))
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const getBatchStudentData = () => {
    app_api
      .get(`/enrolment/batch/${params.id}`)
      .then((res) => {
        setLoading(false)
        setError(null)
        app_api.get(`/exam/${res.data[0].service.exam_id}`).then((res) =>{setExam(res.data.name)})
        setEnrolments(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getBatchData = () => {
    app_api
      .get(`/batch/${params.id}`)
      .then((res) => {
        setLoading(false)
        setError(null)
        setBatch(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getBatchStudentData()
    getBatchData()
  }, [])

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const {
      total_score,
      graceMarks
    } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
              app_api
              .patch(`/enrolment/grace/${edit_id}`, {"grace_marks": values.graceMarks})
              .then((res) => {
                cleanModalData()
                getBatchStudentData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {})
            }
      }
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          setSubmitting,
          isSubmitting, resetForm,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add Details' : 'Edit Details'}
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
            }}
          >
            <form noValidate onSubmit={handleSubmit}>
              <div className="text-left">
                    <div className='mt-4'></div>
                    <p 
                      style={{
                        fontSize: '16px'
                      }}
                    ><b>Total Scored:</b> {modal.data.total_score}</p>
                    <div className='mt-4'></div>
                    <Input
                      name="graceMarks"
                      label="Grace Marks"
                      type="number"
                      value={values.graceMarks}
                      className="mb-2"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.studentsMobileNo && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.studentsMobileNo}
                      </p>
                    )}
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  >
                    { isSubmitting ? (type === 'add' ? 'Adding...' : 'Saving...'):(type === 'add' ? 'Add Details' : 'Update Details' )} 
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      {renderModal()}
      <div className="px-4 pt-5 sm:px-6 lg:px-8 ContainerUI">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">List of Students</h1>
            <p className="my-2 text-sm text-gray-700">
              Student's who are enrolled for {batch.name}.
            </p>
          </div>
        </div>
        <Table
          columns={batch_student_columns({onEditOpen})}
          data={enrolments}
          onEditClose={cleanModalData}
        />
      </div>
    </Sidebar>
  )
}

export default BatchStudent
