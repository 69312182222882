import { useState, useEffect } from 'react'
import Button from '../components/button'
import app_api from '../config/api'
import { Sidebar } from '../components/navigation/sidebar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import {
  CheckIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { classNames } from '../helpers/classname'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    className: '',
    serviceId: '',
    studentId: '',
    gradeId: 0,
    classMode: '',
    locationId: '',
    startDate: '',
    endDate: '',
    subjectId: '',
    teacherId: '',
    teacherHourlyRate: '',
    duration: '',
  },
}

const classModes = [
  {
    id: 'Offline',
    name: 'Offline',
  },
  {
    id: 'Online',
    name: 'Online',
  },
]

const MyClass = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [locationFilter, setLocationFilter] = useState('')
  const [location, setLocation] = useState([])
  const [TabData, setTabData] = useState([])
  const [family, setFamily] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  //pagination End
  const [service, setService] = useState([])
  const [StudentData, setStudentData] = useState([])
  const [teacherAtt, setTeacherAtt] = useState([])
  const [tab, setTab] = useState('Today')
  const navigate = useNavigate()

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const downloadData = () => {
    let payload = []
    let Temppayload = {}
    if (endDate) {
      Temppayload.endDate = endDate
    }
    if (startDate) {
      Temppayload.startDate = startDate
    }
    if (locationFilter) {
      Temppayload.locationId = locationFilter.map((value) => value.id)
    } else {
      Temppayload.locationId = location.map((value) => value.id)
    }
    payload.push(Temppayload)
    app_api
      .post(`credit-note/download`, payload)
      .then((res) => {
        let csvContent =
          'data: text/csv; charset=utf-8, ID, Credit Number, Customer Name, Amount, Payment Method, Payment Date, Location, Description\r\n'
        res.data.forEach((row) => {
          let csvRow = Object.values(row).join(',')
          csvContent += csvRow + '\r\n'
        })
        // csvContent += `\r\n,,Total Invoice Amount,  ${res.data?.totalAmount},\r\n,,Total Payment, ${res.data?.totalPaid},\r\n,,Balance, ${res.data?.totalAmount - res.data?.totalPaid}, \r\n`;
        var encodedUri = encodeURI(csvContent)
        var link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'payment.csv')
        document.body.appendChild(link) // Required for FF
        link.click()
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const onEditOpen = (id, index) => {
    let url = `/class-master/${id}`
    app_api.get(url).then((res) => {
      const data = res.data

      setModal((prev) => ({
        ...prev,
        type: 'edit',
        id: id,
        index: index,
        state: true,
        data: data,
      }))
    })
  }

  const getServiceData = () => {
    let url = `/crm-service/`
    app_api
      .get(url)
      .then((res) => {
        let data = res.data.filter((l) => l.id != 45)
        setService(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, edit_id: id, index: index }))
    setConfirmationDialog(true)
  }

    const cleanModalData = () => {
        setModal(initialModalState)
    }

  const getLocation = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `location/all?&email=${email}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false)
        setError(null)
        setLocation(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getClassData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let locationValues =
      locationFilter && locationFilter.length > 0
        ? locationFilter.map((value) => value.id)
        : location.map((value) => value.id)
    let url = `teacher-master/teacherData/${email}`
    app_api
      .get(url)
      .then((res) => {
        setLoading(false)
        setError(null)
        scheduleData(res.data.id)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const scheduleData = (id) => {
    app_api
      .get(`class-session/teacher/${id}`)
      .then((res) => {
        setCategory(res.data)
      })
      .catch((err) => {
      })
  }
  useEffect(() => {
    getClassData()
  }, [])

  const handleRedirect = (id) => {
    app_api
      .get(`class-session/${id}`)
      .then((res) => {
        setTeacherAtt(res.data)
        setStudentData([])
        getStudentsData(id)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getStudentsData = (id) => {
    app_api
      .get(`student-attendance-master/students/${id}`)
      .then((res) => {
        setError(null)
        setStudentData(res.data)
      })
      .catch((err) => {
      })
  }


  const markAttendance = (index) => {
    const student = StudentData.filter((i) => i.studentId === index)
    let payload = {
      studentIds: index,
      updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
      sessionId: student[0].sessionId,
      teacherId: teacherAtt.teacherId,
    }
    app_api
      .patch(`student-attendance-master/markAttendance`, payload)
      .then((res) => {
        getStudentsData(res.data.sessionId)
      })
      .catch((err) => {
        toast.error('Failed to mark Attendance')
      })
  }

  const markAbsent = (index) => {
    const student = StudentData.filter((i) => i.studentId === index)
    let payload = {
      studentIds: index,
      updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
      sessionId: student[0].sessionId,
      teacherId: teacherAtt.teacherId,
    }
    app_api
      .patch(`student-attendance-master/markAbsent`, payload)
      .then((res) => {
        getStudentsData(res.data.sessionId)
      })
      .catch((err) => {
        toast.error('Failed to mark Attendance')
      })
  }

  return (
    <Sidebar>
      <div className="max-w-[1640px ] mx-auto p-4">
        <div className="px-2 sm:px-6 lg:px-8">
          {loading ? (
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-lg font-semibold text-gray-900 dashboardTitle">
                    My Classes
                  </h1>
                  <p className="my-2 text-sm text-gray-700 ml-2">
                    Here's your list of classes
                  </p>
                </div>
              </div>
              <div className="rounded-lg w-full">
                <hr className="text-gray-400 my-2" />
                <div className="mx-4">
                  <div className="flex justify-between w-full lg:w-1/2 md:px-4 ">
                    <span
                      className={classNames(
                        tab == 'Today'
                          ? 'bg-indigo-800 text-white '
                          : 'transition duration-300',
                        'rounded-md p-2 cursor-pointer hover:bg-indigo-900 hover:text-white'
                      )}
                      onClick={() => {
                        setTab('Today')
                        setStudentData([])
                      }}
                    >
                      Today's Classes
                    </span>
                    <span
                      className={classNames(
                        tab == 'Upcoming'
                          ? 'bg-indigo-800 text-white '
                          : 'transition duration-300',
                        'rounded-md p-2 cursor-pointer hover:bg-indigo-900 hover:text-white'
                      )}
                      onClick={() => {
                        setTab('Upcoming')
                        setStudentData([])
                      }}
                    >
                      Upcoming Classes
                    </span>
                    <span
                      className={classNames(
                        tab == 'Previous'
                          ? 'bg-indigo-800 text-white '
                          : 'transition duration-300',
                        'rounded-md p-2 cursor-pointer hover:bg-indigo-900 hover:text-white'
                      )}
                      onClick={() => {
                        setTab('Previous')
                        setStudentData([])
                      }}
                    >
                      Previous Classes
                    </span>
                  </div>
                </div>
                <hr className="text-gray-400 my-2" />
                <div className="flex flex-col lg:flex-row md:gap-4">
                  <div
                    className={classNames(
                      'w-full lg:w-2/3 md:px-4 mb-4 md:mb-0 overflow-x-auto overflow-y-auto lg:max-h-[800px]'
                    )}
                  >
                    <table className="min-w-full divide-y divide-gray-300 rounded-md bg-gray-200 shadow-sm table-auto">
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                          >
                            Class Name
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                          >
                            Number of Students
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                          >
                            Duration
                          </th>
                          <th
                            scope="col"
                            className="px-2 py-3 text-left text-sm font-semibold text-gray-900 lg:text-lg"
                          >
                            Location
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-gray-50 divide-y divide-gray-500">
                        {tab == 'Previous'
                          ? category.find((index) =>
                            moment(index.date).isBefore(moment(), 'day')
                          )
                            ? category
                              .filter((index) =>
                                moment(index.date).isBefore(moment(), 'day')
                              )
                              .sort(
                                (a, b) =>
                                  moment(a.date).valueOf() -
                                  moment(b.date).valueOf()
                              )
                              .map((classData) => (
                                <tr key={classData.id}>
                                  <td className="px-2 py-3 md:text-base text-xs ">
                                    <span
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleRedirect(classData.id)
                                      }
                                    >
                                      {classData.class.className}
                                    </span>
                                  </td>
                                  <td className="px-2 py-3 md:text-base text-xs ">
                                    {classData.class.students.length}
                                  </td>
                                  <td className="px-2 py-3 md:text-base text-xs ">
                                    {moment(classData.date).format(
                                      'DD-MM-yy'
                                    )}
                                  </td>
                                  <td className="px-2 py-3 md:text-base text-xs ">
                                    {classData.class.duration} min
                                  </td>
                                  <td className="px-2 py-3 text-base">
                                    {classData?.class?.location?.name}
                                    </td>
                                </tr>
                              ))
                            :  <tr>
                            <td className="px-2 py-3 text-base">
                              <span
                                className="cursor-pointer"
                              >
                                There are no records of previous classes.
                              </span>
                            </td>
                            <td className="px-2 py-3 text-base">
                              
                            </td>
                            <td className="px-2 py-3 text-base">
                              {moment().format(
                                'DD-MM-yy'
                              )}
                            </td>
                            <td className="px-2 py-3 text-base">
                              
                            </td>
                            <td className="px-2 py-3 text-base">
                              
                            </td>
                          </tr>
                          : tab == 'Today'
                            ? category.find((index) =>
                              moment(index.date).isSame(moment(), 'day')
                            )
                              ? category
                                .filter((index) =>
                                  moment(index.date).isSame(moment(), 'day')
                                )
                                .sort(
                                  (a, b) =>
                                    moment(a.date).valueOf() -
                                    moment(b.date).valueOf()
                                )
                                .map((classData) => (
                                  <tr key={classData.id}>
                                    <td className="px-2 py-3 text-base">
                                      <span
                                        className="cursor-pointer"
                                        onClick={() =>
                                          handleRedirect(classData.id)
                                        }
                                      >
                                        {classData?.class?.className}
                                      </span>
                                    </td>
                                    <td className="px-2 py-3 text-base">
                                      {classData.class.students.length}
                                    </td>
                                    <td className="px-2 py-3 text-base">
                                      {moment(classData.date).format(
                                        'DD-MM-yy'
                                      )}
                                    </td>
                                    <td className="px-2 py-3 text-base">
                                      {classData.class.duration} min
                                    </td>
                                    <td className="px-2 py-3 text-base">
                                    {classData?.class?.location?.name}
                                    </td>
                                  </tr>
                                ))
                              :  <tr>
                              <td className="px-2 py-3 text-base">
                                <span
                                  className="cursor-pointer"
                                >
                                  No class today
                                </span>
                              </td>
                              <td className="px-2 py-3 text-base">
                                
                              </td>
                              <td className="px-2 py-3 text-base">
                                {/* {moment().format(
                                  'DD-MM-yy'
                                )} */}
                              </td>
                              <td className="px-2 py-3 text-base">
                                
                              </td>
                              <td className="px-2 py-3 text-base">
                                
                              </td>
                            </tr>
                            : 
                            category
                                .filter((index) =>
                                  moment(index.date).isAfter(moment(), 'day')
                                )
                                .sort(
                                  (a, b) =>
                                    moment(a.date).valueOf() -
                                    moment(b.date).valueOf()
                                )
                                .map((classData) => (
                                  <tr key={classData.id}>
                                    <td className="px-2 py-3 text-base">
                                      <span
                                        className="cursor-pointer"
                                        onClick={() =>
                                          handleRedirect(classData.id)
                                        }
                                      >
                                        {classData?.class?.className}
                                      </span>
                                    </td>
                                    <td className="px-2 py-3 text-base">
                                      {classData.class.students.length}
                                    </td>
                                    <td className="px-2 py-3 text-base">
                                      {moment(classData.date).format(
                                        'DD-MM-yy'
                                      )}
                                    </td>
                                    <td className="px-2 py-3 text-base">
                                      {classData.class.duration} min
                                    </td>
                                    <td className="px-2 py-3 text-base">
                                    {classData?.class?.location?.name}
                                    </td>
                                  </tr>
                                ))
                            }
                      </tbody>
                    </table>
                  </div>
                  <div className="flex flex-col w-full lg:w-1/2 md:px-4">
                    <div className="flex flex-col gap-4">
                      <div className="w-full">
                        {StudentData.length > 0 ? (
                          <>
                            <div className="flex justify-between flex-col md:flex-row md:items-center items-start px-2 bg-gray-200 py-3 rounded-md md:px-4 md:gap-4">
                              <div className="">
                                <h1 className=" text-gray-900">
                                  <span className="text-lg font-semibold">
                                    Attendees
                                  </span>
                                </h1>
                              </div>
                              <div className="">
                                <h1>
                                  <span className="text-md">
                                    {StudentData[0].session.class.className} -{' '}
                                    {StudentData[0].session.class.subject.subject} (
                                    {moment(StudentData[0].session.start_time).format(
                                      'lll'
                                    )}{' '}
                                    -{' '}
                                    {moment(StudentData[0].session.end_time).format(
                                      'h:mm A'
                                    )}
                                    )
                                  </span>
                                </h1>
                              </div>
                            </div>
                            <div className="rounded-lg overflow-x-auto overflow-y-auto lg:h-5/6">
                              <table className="min-w-full divide-y divide-gray-300 py-2 align-middle sm:px-6 lg:px-8 px-2 md:px-4">
                                <tbody className="bg-gray-50 divide-y divide-gray-500">
                                  {StudentData.map((index) => (
                                    <tr key={index?.student?.id}>
                                      <td className="px-4 w-10 text-center text-gray-900">
                                        <UserIcon className="text-indigo-600 rounded-full p-2 bg-indigo-300 w-10" />
                                      </td>
                                      <td className="px-2 py-3 text-left text-gray-900 ">
                                        <div className="flex flex-col">
                                          <span>
                                            {index?.student?.studentsFirstName +
                                              ' ' +
                                              index?.student?.studentsLastName}
                                          </span>
                                          <span className="text-slate-500">
                                            Student
                                          </span>
                                        </div>
                                      </td>
                                      <td className="px-4 py-3 md:w-40 text-center text-gray-900">
                                        <div className="text-center text-gray-900 flex gap-2">
                                          <button
                                            className={classNames(index.attendance == 0 ? 'bg-red-600 text-white' : 'bg-white text-red-500 border-red-500 hover:text-red-700 disabled:text-gray-400 ', 'border  duration-500 inline-flex justify-center rounded-full disabled:cursor-not-allowed px-1 py-1')}
                                            onClick={() => {
                                              markAbsent(index?.student?.id)
                                            }}
                                            type={'button'}
                                            disabled={tab == 'Upcoming'|| index.attendance == 0}
                                          >
                                            <XMarkIcon className="w-8 " />
                                          </button>
                                          <button
                                            className={classNames(index.attendance == 1 ? 'bg-green-800 text-white ' : 'bg-white text-green-500 border-green-500 hover:text-green-700 disabled:text-gray-400 ', 'border  duration-500 inline-flex justify-center rounded-full disabled:cursor-not-allowed px-1 py-1')}
                                            onClick={() => {
                                              markAttendance(index?.student?.id)
                                            }}
                                            disabled={tab == 'Upcoming' || index.attendance == 1}
                                          >
                                            <CheckIcon className="w-8 " />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <div className="flex justify-between flex-col md:flex-row md:items-center items-start px-2 bg-gray-200 py-3 rounded-md md:px-4 md:gap-4">
                            <div className="">
                              <h1 className=" text-gray-900">
                                <span className="text-lg font-semibold">
                                  Attendees
                                </span>
                              </h1>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default MyClass
