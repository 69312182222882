import { Link, useNavigate } from "react-router-dom"
import { Sidebar } from "../components/navigation/sidebar"
import { Button } from "../components/tables/paginationButtons"
import { BanknotesIcon, Bars3BottomLeftIcon, BookOpenIcon, EnvelopeOpenIcon, InboxIcon, NewspaperIcon } from "@heroicons/react/24/outline"


const SettingPage = () => {
    const navigate = useNavigate()

    const userData = JSON.parse(localStorage.getItem('moldKey'))

    const pages = [
        { name: 'Workshop', href: '/setting/naplan', icon: NewspaperIcon },
        { name: 'Discount Master', href: '/setting/discount-master', icon: BanknotesIcon },
        { name: 'Tuition Master', href: '/setting/tuition-master', icon: EnvelopeOpenIcon },
        { name: 'Grades', href: '/setting/grade-master', icon: Bars3BottomLeftIcon, },
        { name: 'Subjects', href: '/setting/subject-master', icon: BookOpenIcon, },
        { name: 'CRM service', href: '/setting/crm-service', icon: InboxIcon, },
        { name: 'Term Dates', href: '/setting/term-master', icon: NewspaperIcon, },
    ]

    return (
        <Sidebar>
            <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
                <div className="sm:flex sm:items-center py-2">
                    <div className="sm:flex-auto">
                        <h1 className="text-3xl font-semibold text-gray-900">
                            Settings Page
                        </h1>
                        {/* <p className="my-2 text-sm text-gray-700">
                A list of all the naplan exams pdf.
              </p> */}
                    </div>
                    {/* <div className="sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                onClick={() =>
                  setModal((prev) => ({ ...prev, type: 'add', state: true }))
                }
                className="flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mt-0 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Add
              </Button>
            </div> */}
                </div>
                <div
                    role="list"
                    className="grid grid-cols-1 gap-5 mt-4 sm:grid-cols-2 sm:gap-12 lg:grid-cols-4 md:grid-cols-3 mt-4"
                >
                    {pages.map((i) => {
                        if(userData.role == "manager" && i.href == '/setting/term-master'){
                            return <></>
                        }else{
                            return (
                                <Link to={i.href} className="py-1 my-1">
                                    <div
                                        key={i.name}
                                        className="flex flex-col h-[150px] border-gray-200 border-t border-b border-r rounded-md shadow-md transition duration-500 ease-in-out cursor-pointer mr-4 hover:bg-indigo-600 justify-center items-center hover:text-white text-indigo-600 gap-2 font-serif tracking-wider"
                                    >
                                        <span>
                                            <i.icon className="w-16 text-2xl" />
                                        </span>
                                        <span className="text-xl">{i.name}</span>
                                    </div>
                                </Link>
                            )
                        }
                    })}
                </div>
            </div>
        </Sidebar>
    )
}

export default SettingPage