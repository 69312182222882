import { useState, useEffect } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { crm_service_columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { servicesTypeClassesSchema } from '../schema'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input-field'
import { Switch } from '@headlessui/react'
import { classNames } from '../helpers/classname'
import { ToastContainer, toast } from 'react-toastify'
import HalfGeneralSlideover from '../components/half-general-slideover'
import { ArrowUpCircleIcon, Cog8ToothIcon, PhotoIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import BigSlideover from '../components/big-slideover'
import { CiFilter } from 'react-icons/ci'


const initialModalState = {
  type: 'add',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    cost: '',
    description: '',
    grade: '',
    sessionType: '',
    typeOf: '',
    files: '',
  },
}

const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 15,
  }),
}

const groupSizeOptions = [
  {
    id: 'individual',
    name: 'Individual',
  },
  {
    id: 'group',
    name: 'Group',
  },
]

const typeOfServiceOptions = [
  {
    id: 'Regular',
    name: 'Regular',
  },
  {
    id: 'Workshop',
    name: 'Workshop',
  },
]

const initialMarkAsPaidModalState = {
  data_type: 'add',
  data_state: false,
  data_index: null,
  data_id: '',
}

const CRMService = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [service, setService] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [grade, setGrade] = useState([])
  const [stateFilter, setStateFilter] = useState()
  const [enabledSub, setEnabledSub] = useState(true)
  const [cityFilter, setCityFilter] = useState()
  const [filerServices, setFilterServices] = useState([])
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End
  const [serviceFilter, setServiceFilter] = useState([])
  const [files, setFiles] = useState([])
  // const [markAsPaidModal, setMarkAsPaidModal] = useState(initialMarkAsPaidModalState)
  const [exams, setExams] = useState([])
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [filterData, setFilterData] = useState({
    grade: '',
    typeOf: '',
    GroupSize: '',
    loading: false
  })

  const handleFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })
    console.log(filterData)
    setStateFilter(filterData.grade)
    setCityFilter(filterData.GroupSize)
    setServiceFilter(filterData.typeOf)
    setIsFilterSidebarOpen(false)
  }

  const handleClearFilters = () => {
    setStateFilter()
    setCityFilter()
    setServiceFilter([])
    setIsFilterSidebarOpen(false)
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  useEffect(() => {
    getServiceData()
  }, [page, limit, searchFilter, stateFilter, cityFilter, serviceFilter])

  const onEditOpen = (id, index) => {
    console.log(`onEdit`, filerServices[index])
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: filerServices[index],
    }))
    setEnabledSub(filerServices[index].archive)
    setExams(filerServices[index].exam)
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onDeleteService = () => {
    const { id, index } = modal
    app_api
      .patch(`crm-service/${id}`, { archive: false })
      .then((res) => {
        getServiceData()
        toast.success("Successfully inactivated")
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const onActiveCategory = (id) => {
    app_api
      .patch(`crm-service/${id}`, { archive: true })
      .then((res) => {
        getServiceData()
        toast.success("Successfully activated")
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  useEffect(() => {
  }, [stateFilter])

  const getServiceData = () => {
    let url = `/crm-service/all?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    if (stateFilter?.value) url = url + `&grade=${stateFilter.value}`
    if (cityFilter?.value) url = url + `&group=${cityFilter.value}`
    if (serviceFilter?.value) url += `&service=${serviceFilter.value}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        let data = res.data
        setService(data)
        setFilterServices(data)
        setFilterData({
          ...filterData,
          loading: false
        })
        if (res.count <= limit) setPage(0)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
        if (isNaN(res.count / limit)) { setTotalPages(1) } else { setTotalPages(res.count / limit) }
        setCount(res.count)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const uploadedFiles = (id, files) => {
    let fd = new FormData()
    fd.append('id', id)
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        fd.append('file', files[i])
      }
    }
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    app_api
      .post(`/pdf-uploads`, fd, { headers })
      .then((res) => {
        getServiceData()
        setExams(res.data.exam)
        toast.success('Successfully added file')
      }).catch((err) => {
        toast.error('Failed to upload file')
      })
  }


  const getGradeData = () => {
    app_api
      .get('/grade-master')
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setGrade(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getServiceData()
    getGradeData()
  }, [])

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    return (
      <Formik
        initialValues={data}
        validationSchema={servicesTypeClassesSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          delete values.pdfId
          values.pdfId = exams?.map((e) => e.naplanExamId)
          let fd = new FormData()
          if (values.files && values.files.length > 0) {
            for (let i = 0; i < values.files.length; i++) {
              fd.append('file', values.files[i])
            }
          }
          fd.append('name', values.name);
          fd.append('cost', parseInt(values.cost));
          fd.append('grade', parseInt(values.grade));
          fd.append('sessionType', values.sessionType);
          fd.append('description', values.description);
          fd.append('typeOf', values.typeOf);
          fd.append('archive', String(enabledSub));
          const headers = {
            'Content-Type': 'multipart/form-data',
          }
          if (type === 'add') {
            app_api.post(`crm-service/upload`, fd, { headers })
              .then((res) => {
                cleanModalData()
                toast.success("Successfully added")
                getServiceData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                setError(err.toString)
              })
          } else {
            app_api
              .patch(`crm-service/${edit_id}`, {
                name: values.name,
                cost: parseInt(values.cost),
                grade: parseInt(values.grade),
                sessionType: values.sessionType,
                description: values.description,
                archive: enabledSub,
                typeOf: values.typeOf,
                pdfId: values.pdfId,
              })
              .then((res) => {
                getServiceData()
                cleanModalData()
                toast.success("Successfully updated")
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                setError(err.toString)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            title={type === 'add' ? 'Add CRM Service' : 'Edit CRM Service'}
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 flex flex-wrap mt-4'>
                <div className="col-span-2 lg:mt-4">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="name"
                    label="Name"
                    value={values.name}
                    placeholder="Enter Name"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={() => setFieldTouched('name', false)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {touched.name && (
                    <p className="text-red-700 error_msg">{errors.name}</p>
                  )}
                </div>

                {/* Classes */}
                {values.name != 'Admin Charge' && (
                  <>
                    <div className="col-span-2 lg:mt-4">

                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Grade
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>

                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="grade"
                        id="grade"
                        isSearchable
                        loadingMessage="Getting Exam..."
                        placeholder="Select a Grade"
                        value={
                          values.grade
                            ? {
                              value: grade?.find((e) => e.id == values.grade)?.id,
                              label: grade?.find((e) => e.id == values.grade)
                                ?.grade,
                            }
                            : null
                        }
                        options={grade.map((l) => ({
                          ...l,
                          label: l.grade,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            grade: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        onFocus={() => setFieldTouched('grade', false)}
                      />
                      {touched.grade && (
                        <p className="text-red-700 error_msg ">{errors.grade}</p>
                      )}
                    </div>

                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Type of Service
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>

                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="typeOf"
                        id="typeOf"
                        isSearchable
                        loadingMessage="Getting Group Size..."
                        placeholder="Select a type of service..."
                        value={
                          values.typeOf
                            ? typeOfServiceOptions?.map((l) => ({
                              value: typeOfServiceOptions?.find(
                                (e) => e.id == values.typeOf
                              )?.id,
                              label: typeOfServiceOptions?.find(
                                (e) => e.id == values.typeOf
                              )?.name,
                            }))
                            : null
                        }
                        options={typeOfServiceOptions.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            typeOf: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        onFocus={() => setFieldTouched('typeOf', false)}
                      />
                      {touched.typeOf && (
                        <p className="text-red-700 error_msg ">
                          {errors.typeOf}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">

                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Group Size
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>

                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="sessionType"
                        id="sessionType"
                        isSearchable
                        loadingMessage="Getting Group Size..."
                        placeholder="Select a Group Size..."
                        value={
                          values.sessionType
                            ? groupSizeOptions?.map((l) => ({
                              value: groupSizeOptions?.find(
                                (e) => e.id == values.sessionType
                              )?.id,
                              label: groupSizeOptions?.find(
                                (e) => e.id == values.sessionType
                              )?.name,
                            }))
                            : null
                        }
                        options={groupSizeOptions.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            sessionType: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        onFocus={() => setFieldTouched('sessionType', false)}
                      />
                      {touched.sessionType && (
                        <p className="text-red-700 error_msg ">
                          {errors.sessionType}
                        </p>
                      )}
                    </div>
                  </>
                )}

                <div className="col-span-2 lg:mt-4">

                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Cost
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>

                  <CurrencyInput
                    id="cost"
                    name="cost"
                    placeholder="Please enter cost amount"
                    decimalsLimit={2}
                    value={values.cost}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    prefix="$"
                    onValueChange={(value) => {
                      setValues({
                        ...values,
                        cost: value,
                      })
                    }}
                    onFocus={() => setFieldTouched('cost', false)}
                  />
                  {touched.cost && (
                    <p className="text-red-700 error_msg ">{errors.cost}</p>
                  )}
                </div>
                <div className='lg:mt-10 col-span-2'>
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Service Status
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={enabledSub}
                      onChange={setEnabledSub}
                      className={classNames(
                        enabledSub ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabledSub ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <div className="col-span-2 lg:mt-4">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    placeholder="Please enter the description"
                    name="description"
                    rows={4}
                    cols={40}
                    value={values.description}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(event) => {
                      setValues({
                        ...values,
                        description: event.target.value,
                      })
                    }}
                    onFocus={() => setFieldTouched('description', false)}
                  ></textarea>
                </div>

                {values.typeOf == "Workshop" && (
                  <>
                    <div className="w-full col-span-2 bg-gray-50 p-6 rounded-md shadow-md">
                      <h2 className="text-2xl font-bold text-gray-900 flex justify-center mt-2">Uploads</h2>
                      <div
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          e.preventDefault();
                          const data = e.target.files;
                          const newData = Array.from(data);
                          const updatedFiles = [...files, ...newData];
                          setValues({
                            ...values,
                            files: updatedFiles,
                          });
                        }}
                        className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                      >
                        <div className="text-center">
                          <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                          <div className="mt-4 flex text-sm leading-6 text-primary">
                            <label
                              htmlFor="fileName"
                              className="relative flex cursor-pointer rounded-md bg-white font-semibold text-focus:ring-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-focus:ring-primary focus-within:ring-offset-2 hover:text-primary-300"
                            >
                              <span>Upload a file</span>
                              <input
                                id="fileName"
                                name="fileName"
                                type="file"
                                className="sr-only"
                                onChange={(e) => {
                                  const data = e.target.files;
                                  const newData = Array.from(data);
                                  const updatedFiles = [...files, ...newData];
                                  console.log(updatedFiles)
                                  setValues({
                                    ...values,
                                    files: updatedFiles,
                                  });
                                }}
                                multiple
                              />
                              <p className="pl-1">or drag and drop</p>
                            </label>
                          </div>
                          <p className="text-xs leading-5 flex w-full items-center justify-center gap-3 text-gray-600">
                            {values.files && values.files.length > 0 ? (
                              <p>
                                {values.files.map((s) => (
                                  <span className="flex gap-2">
                                    <p>{s.name}</p>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setValues({
                                          ...values,
                                          files: values.files.filter((e) => e !== s),
                                        });
                                      }}
                                      className="cursor-pointer text-red-500 px-1 py-1 hover:bg-red-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                                    >
                                      <XMarkIcon className="w-4" />
                                    </button>
                                  </span>
                                ))}
                              </p>
                            ) : (
                              "PDF"
                            )}
                          </p>
                        </div>
                      </div>
                      {type == "edit" && values.typeOf === "Workshop" && (
                        <div className="flex justify-end mt-1">
                          <Button
                            type={'button'}
                            onClick={() => uploadedFiles(values.id, values.files)}
                            className='w-1/3 flex gap-2'><ArrowUpCircleIcon className='w-5' /> Upload</Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {type === 'edit' && values.typeOf === "Workshop" && (
                <div className="mt-4 rounded-lg overflow-auto max-h-[400px] overflow-y-auto">
                  <h2 className="font-bold text-lg">Exam Files</h2>
                  <table className="min-w-full divide-y divide-gray-300 py-2 align-middle sm:px-6 lg:px-8">
                    <thead className="bg-gray-50 top-0 z-10">
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                          File name
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                          <span className="px-2">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-gray-50 divide-y divide-gray-500 ">
                      {exams.map((s) => (
                        <tr key={s.id}>
                          <td className="px-4 py-3 text-sm">
                            {s.pdf.fileName}
                          </td>
                          <td className="px-4 py-3 text-sm ">
                            <button type='button'
                              onClick={() => {
                                const filteredExam = exams.filter((l) => l.id !== s.id);
                                setValues({
                                  ...values,
                                  exam: filteredExam,
                                });
                                setExams(filteredExam)
                              }}
                              className="cursor-pointer text-red-500 px-2 py-1 hover:bg-red-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                            ><TrashIcon className="w-5" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="mt-3 sm:mt-6">
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting
                    ? type === 'add'
                      ? 'Adding...'
                      : 'Saving...'
                    : type === 'add'
                      ? 'Add CRM Service'
                      : 'Update CRM Service'}
                </Button>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  return (
    <>
      <Sidebar>
        <ConfirmationDialog
          setOpen={setConfirmationDialog}
          open={confirmationDialog}
          onDelete={onDeleteService}
          confirmationButtonText="Inactive"
          description='Do you really want to inactivate this data?'
        />
        {renderModal()}
        <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-semibold text-gray-900">
                CRM Services
              </h1>
              {/* <p className="my-2 text-sm text-gray-700">
                A list of all the services.
              </p> */}
              <nav className="flex mt-4 " aria-label="Breadcrumb">
                <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
                  <li className="flex">
                    <div className="flex items-center">
                      <Link to="/setting" className="text-gray-400 hover:text-gray-500 flex items-center gap-2">
                        <Cog8ToothIcon className="h-5 w-5 flex-shrink-0 " aria-hidden="true" />
                        <span className="">Setting</span>
                      </Link>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex items-center">
                      <svg
                        className="h-full w-6 flex-shrink-0 text-gray-200"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      <Link
                        to={'/setting/crm-service'}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current='page'
                      >
                        CRM Service
                      </Link>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                onClick={() =>
                  setModal((prev) => ({ ...prev, type: 'add', state: true }))
                }
                className="flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mt-0 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Add
              </Button>
            </div>
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) :
            <>
              <div className="relative mx-2">
              <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
                <div></div>
                <div className="flex justify-between gap-4 ">
                  <div
                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                  >
                    {filterData.loading ? (
                      <div className="flex items-center justify-center">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                        </div>
                      </div>) : <CiFilter className="w-6 h-6" />}
                  </div>
                </div>
              </div>

              {isFilterSidebarOpen && (
                <div className='h-full'>
                  <div className={`absolute bg-white  border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-50`}>
                    <div className='flex justify-between p-2'>
                      <span className=" text-sm text-gray-700 ">Filters</span>
                      <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                        setFilterData({
                          grade: '',
                          typeOf: '',
                          GroupSize: '',
                          loading: false
                        })
                        handleClearFilters()
                      }}> Reset All </span>
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Type of Service</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            typeOf: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="typeOf"
                        id="typeOf"
                        isSearchable
                        loadingMessage="Getting Group Size..."
                        placeholder="Select a type of service..."
                        isClearable={true}
                        options={typeOfServiceOptions.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        value={filterData.typeOf}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            typeOf: option,
                          })
                        }}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                      />
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Grade</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            grade: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        key="Grade"
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="grade"
                        id="grade"
                        placeholder="Filter By Grade"
                        isClearable={true}
                        options={grade?.map((l) => ({
                          ...l,
                          label: l.grade,
                          value: l.id,
                        }))}
                        value={filterData.grade}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            grade: option,
                          })
                        }}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                      />
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Group Size</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            GroupSize: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        key="classType"
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="groupSizeOptions"
                        id="groupSizeOptions"
                        placeholder="Filter By Group Size"
                        isClearable={true}
                        options={groupSizeOptions?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        value={filterData.GroupSize}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            GroupSize: option,
                          })
                        }}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                      />
                    </div>
                    <hr className='' />
                    <div className="flex justify-between gap-2 p-2">
                      <div className="">
                        <Button
                          type="button"
                          className={'bg-red-500 hover:bg-red-700'}
                          onClick={() => {
                            setFilterData({
                              grade: '',
                          typeOf: '',
                          GroupSize: '',
                          loading: false
                            })
                            handleClearFilters()
                          }}
                        >
                          Reset All
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          type="button"
                          onClick={() => {
                            handleFilters()
                          }}
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
              <Table
                progPage={page}
                totalCount={count}
                columns={crm_service_columns({ onEditOpen, onDeleteOpen, onActiveCategory })}
                data={filerServices}
                onEditClose={cleanModalData}
                setSearchFilter={setSearchFilter}
              />
              <nav
                className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <select
                      onChange={(e) => {
                        setLimit(e.target.value)
                        setPage(0)
                      }}
                      value={limit}
                    >
                      <option value={count}>{count}</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>{' '}
                    of <span className="font-medium">{count}</span> results
                  </p>
                </div>
                <div className="flex items-center md:justify-end">
                  <span
                    onClick={goPrev}
                    className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Previous
                  </span>
                  <span className="text-sm">
                    Page{' '}
                    <input
                      className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                      type="number"
                      value={page + 1}
                      onChange={(e) => setPage(e.target.value - 1)}
                      max={totalPages}
                      min="1"
                    />
                    <span className='ml-2'></span>/ {Math.ceil(totalPages)}
                  </span>
                  <span
                    onClick={goNext}
                    className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Next
                  </span>
                </div>
              </nav>
            </>}
        </div>
        <ToastContainer
          closeButton={false}
          closeOnClick={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          autoClose={2000}
          hideProgressBar={false} />
      </Sidebar>
    </>
  )
}

export default CRMService
