import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { reference_columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { referenceSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    email: '',
  },
}

const Reference = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reference, setReference] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  const [pagingData, setPagingData]= useState({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
      order: "",
      key: "",
    },
  })

  useEffect(() => {
    setPagingData({
      ...pagingData,
      total: TabData.length
    })
  },[TabData])

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          onPaginationChange={onPaginationChange}
          pagingData={tableData}
          columns={reference_columns({ onEditOpen, onDeleteOpen })}
          data={
            TabData
          }
          onEditClose={cleanModalData}
        />
      </>
    )
  }, [TabData])

  const { pageIndex, pageSize, sort, query, total } = pagingData;

  const tableData = useMemo(
    () => ({ pageIndex, pageSize, sort, query, total }),
    [pageIndex, pageSize, sort, query, total]
  );

  const onPaginationChange = (page) => {
    let newService = [...reference]
    setTabData(newService.slice((page-1) * pageSize))
    const newTableData = {...tableData};
    newTableData.pageIndex = page;
    setPagingData(newTableData)
  };

  useEffect(() => {
    if(pageIndex != 1){
      const newService = [...reference]
      setTabData(newService.slice((pageIndex - 1) * pageSize))
    }
  }, [reference])

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: reference[index],
    }))
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .patch(`/referer/${id}`,{'archive': 1})
      .then((res) => {
        getReferData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const onModalHandleChange = (name, value) => {
    setModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }))
  }

  const getReferData = () => {
    app_api
      .get('/referer')
      .then((res) => {
        let data = res.data.filter(l => l.archive == 0)
        setLoading(false)
        setError(null)
        setReference(data)
        setTabData(data)
        setPagingData({
          ...pagingData,
          total: data.length
        })
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getReferData()
  }, [])

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const { reference } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={referenceSchema}
        enableReinitialize
        onSubmit={(values, {setSubmitting}) => {
          if (type === 'add') {
            app_api
              .post('/referer', values)
              .then((res) => {
                cleanModalData()
                setSubmitting(false)
                getReferData()
              })
              .catch((err) => {
                if(err.response.status === 424) toast.error('Duplicate Entry')
                else toast.error('Something Went Wrong')
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/referer/${edit_id}`, values)
              .then((res) => {
                getReferData()
                cleanModalData()
                setSubmitting(false)
              })
              .catch((err) => {
                toast.error('Something Went Wrong')
                cleanModalData()
                setSubmitting(false)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add Referral' : 'Edit Referral'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Name</label>
                  <span className='text-red-700 ml-1 mb-1'>*</span>
                </div>
                <input
                  name="name"
                  label="Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Name..."
                  onFocus={()=>setFieldTouched('name',false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Email</label>
                  <span className='text-red-700 ml-1 mb-1'>*</span>
                </div>
                <input
                  name="email"
                  value={values.email}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter email address..."
                  onFocus={()=>setFieldTouched('email',false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.email && (
                  <p className="text-red-700 error_msg">{errors.email}</p>
                )}
                <div className="mt-4 sm:mt-6">
                  <Button type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (type === 'add' ? 'Adding...' : 'Saving...') : (type === 'add' ? 'Add Referral' : 'Update Referral')}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Referral</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the referral.
            </p>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() =>
                setModal((prev) => ({ ...prev, state: true, type: 'add' }))
              }
              className="flex justify-center items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mt-0 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {/* <Table
          columns={reference_columns({ onEditOpen, onDeleteOpen })}
          data={reference}
          onEditClose={cleanModalData}
          className="-striped -highlight"
        /> */}
        {renderTable()}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default Reference
