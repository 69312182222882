import { useState, useEffect } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { session_type__columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { sessionTypeSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    sessionType:''
  },
}

const SessionTypeMaster = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [categoryFilter, setCategoryFilter] = useState('')
  const [categoryTypes, setCategoryTypes] = useState([])

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: category[index],
    }))
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .patch(`/sessionType/${id}`, { archive: 1 })
      .then((res) => {
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const onModalHandleChange = (name, value) => {
    setModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }))
  }

  const getCategoryData = () => {
    app_api
      .get('/sessionType')
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setCategory(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getCategoryData()
  }, [])

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const { sessionType } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={sessionTypeSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          if (type === 'add') {
            app_api
              .post('/sessionType', values)
              .then((res) => {
                cleanModalData()
                getCategoryData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)

              })
          } else {
            app_api
              .patch(`/sessionType/${edit_id}`, values)
              .then((res) => {
                getCategoryData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {})
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add Session Type' : 'Edit Session Type'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <Input
                  name="sessionType"
                  label="Session Type"
                  value={values.sessionType}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Session Type..."
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                {touched.sessionType && (
                  <p className="text-red-700 error_msg">{errors.sessionType}</p>
                )}
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    // onClick={() => {
                    //   type === 'add'
                    //     ? createCategory()
                    //     : updateCategory(edit_id)
                    // }}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Session Type'
                      : 'Update Session Type'}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Session Type</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the session type.
            </p>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() =>
                setModal((prev) => ({ ...prev, state: true, type: 'add' }))
              }
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {/* <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2">
          <div className="w-1/4 ml-1">
            <Select
              className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              name="categoryType"
              id="categoryType"
              placeholder="Category Type"
              isClearable={true}
              options={categoryTypes?.map((l) => ({
                ...l,
                label: l,
                value: l,
              }))}
              onChange={(option) =>
              { setCategoryFilter(option) }}
              isLoading={loading}
            />
          </div>
        </div> */}
        <Table
          columns={session_type__columns({ onEditOpen, onDeleteOpen })}
          data={category}
          onEditClose={cleanModalData}
          className="-striped -highlight"
        />
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default SessionTypeMaster
