import React from 'react'
import Sign from '../assets/signature.png'

const GradeSheet = () => {
  return (
    <div>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-2xl dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <img
              className="w-22 h-10 mx-auto"
              src="https://www.nrichlearning.com.au/wp-content/uploads/2022/09/e-nrich-logo-b.png"
              alt="Your Company"
            />
            <div className="flex flex-wrap w-12/12 mt-10 mb-20">
              <p className=" w-6/12 text-sm text-gray-400">
                Name: <br />
                <span className="text-lg text-black font-medium">
                  Rishabh Mehta
                </span>
              </p>
              <p className=" w-6/12 text-sm text-gray-400">
                Email: <br />
                <span className="text-lg text-black font-medium">
                  rishabhmehta@gmail.com
                </span>
              </p>
              <p className="mt-5 w-6/12 text-sm text-gray-400">
                Batch: <br />
                <span className="text-lg text-black font-medium">Batch 1</span>
              </p>
              <p className="mt-5 w-6/12 text-sm text-gray-400">
                Service: <br />
                <span className="text-lg text-black font-medium">
                  Verbal Reasoning Service
                </span>
              </p>
            </div>
            <div>
              <h2 className="font-bold text-3xl text-center">
                Class GradeSheet
              </h2>

              <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-white uppercase bg-indigo-800 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Subject
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Score
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Out of
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        English
                      </th>
                      <td className="px-6 py-4">75</td>
                      <td className="px-6 py-4">100</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Maths
                      </th>
                      <td className="px-6 py-4">75</td>
                      <td className="px-6 py-4">100</td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Science
                      </th>
                      <td className="px-6 py-4">75</td>
                      <td className="px-6 py-4">100</td>
                    </tr>
                    <tr className="bg-gray-100 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Total
                      </th>
                      <td className="px-6 py-4">225</td>
                      <td className="px-6 py-4">300</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-full flex mt-10">
              <div className="w-8/12 text-center flex flex-wrap justify-center">
                <p className="w-6/12 text-gray-500 font-semibold text-xl">
                  Percentage: <span className="text-black">75%</span>
                </p>
                <p className="w-6/12 text-gray-500 font-semibold text-xl">
                  Grade: <span className="text-black">A</span>
                </p>
                <p className="mt-3 w-6/12 text-gray-500 font-semibold text-xl">
                  Date: <span className="text-black">15 January 2023</span>
                </p>
              </div>
              <div className="w-4/12 ">
                <p className="ml-auto w-6/12 text-gray-500 font-medium text-xl">
                  Signature:
                </p>
                <img
                  className=" ml-auto w-22 h-16 "
                  src={Sign}
                  alt="Your Company"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default GradeSheet
