import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function ColumnSlideOver(props) {
  const { title, description, open, setOpen, handleClick } = props
  const [sample, setSample] = useState([])

  const handleChanges = (id, event) => {
    let data = []
    data = sample
    data[id].active = event
    setSample(data)
  }

  const setColumnData = () => {
    props.columns = sample
  }

  useEffect(() => {
    setSample(props.data)
  }, [props])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-indigo-900 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">{description}</p>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {sample.map((columns, i) => {
                        return (
                          <>
                            <div
                              class="flex items-center pl-4 border border-gray-200 rounded mb-1"
                            >
                              <input
                                defaultChecked={columns.active}
                                id="bordered-checkbox-2"
                                type="checkbox"
                                onChange={(e) => {
                                  handleChanges(i, e.target.checked)
                                }}
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label
                                class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                              >
                                {columns?.header}
                              </label>
                            </div>
                          </>
                        )
                      })}
                    </div>
                    <div className="bottom-0 h-12 bg-white flex justify-center">
                      <div className="w-full mx-2 my-2 flex justify-center">
                        <button className="h-8 bg-indigo-800 rounded-lg duration-300 bg-indigo-900 w-full text-white flex justify-center items-center hover:bg-indigo-700" onClick={()=> {
                          handleClick && handleClick(sample)
                        }}>
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
