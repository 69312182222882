import React, { useEffect } from 'react'
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { XMarkIcon } from '@heroicons/react/24/outline'

const VARIANTS = {
  Info: {
    base: 'bg-white border-blue-500',
    iconstyle: 'text-blue-500 ',
    icon: InformationCircleIcon,
    name: 'Info',
  },

  Error: {
    base: 'bg-white border-red-500 ',
    iconstyle: 'text-red-500 ',
    icon: ExclamationCircleIcon,
    name: 'Error',
  },

  Warning: {
    base: 'bg-white border-yellow-500',
    iconstyle: 'text-yellow-500 ',
    icon: ExclamationCircleIcon,
    name: 'Warning',
  },

  Success: {
    base: 'bg-white border-green-500',
    iconstyle: 'text-green-500 ',
    icon: CheckCircleIcon,
    name: 'Success',
  },
}

export default function ToastMessage({
  id,
  header,
  message,
  lifetime,
  onRemove,
  truncate = 'truncate-1-lines',
  icon,
  type,
}) {
  const Var = type
    ? VARIANTS[type]
    : {
        base: 'bg-white border-gray-600 ',
        iconstyle: '',
        icon: icon,
        name: header,
      }

  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id)
      }, lifetime)
    }
  }, [lifetime])

  const { icon: ToastIcon } = Var

  return (
    <div
      className={clsx(
        'flex w-full visible flex-row shadow-lg',
        'border-l-4 rounded-md duration-100 cursor-pointer',
        'transform transition-all hover:scale-102',
        Var.base,
        type && 'max-h-40'
      )}
    >
      <div className="flex flex-row flex-no-wrap w-full p-2">
        {Var.icon && (
          <div
            className={clsx(
              'flex items-center h-12 w-12',
              'mx-auto text-xl select-none'
            )}
          >
            <ToastIcon className={clsx(Var.iconstyle, 'h-6 w-6 ')} />
          </div>
        )}

        <div className="flex flex-col flex-no-wrap w-full px-1">
          <div className="flex my-auto font-bold select-none">{Var.name}</div>
          <p
            className={clsx(
              '-mt-0.5 my-auto break-all flex',
              'text-gray-600 text-sm',
              typeof message === 'string' && truncate
            )}
          >
            {message}
          </p>
        </div>
        <div
          onClick={() => onRemove && onRemove(id)}
          className={clsx(
            'w-10 h-12 mr-2 items-center mx-auto',
            'text-center leading-none text-lg'
          )}
        >
          <XMarkIcon
            className={clsx(
              'mx-auto my-auto h-6 w-6 text-center text-gray-600',
              'cursor-pointer hover:scale-105 transform '
            )}
          />
        </div>
      </div>
    </div>
  )
}
